import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';

const LocationInfo = ({ suburb, city, locationCoordinates }) => {

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const [active, setActive] = useState(false);

  const mapStyle = {
    height: '400px',
    width: '100%',
    zIndex: 0, // Ensure map is interactive when active
  };

  // Function to toggle map interaction
  const toggleActive = () => {
    setActive(!active);
    console.log('Map interaction toggled');
  };

  // Use the locationCoordinates prop if provided, otherwise default to center
  const position = locationCoordinates ? {
    lat: locationCoordinates.latitude,
    lng: locationCoordinates.longitude
  } : {
    lat: -33.913720511267755,   // Example latitude
    lng: 18.391466266061094 // Example longitude
  };

  return (
    <div className="location-info">
      <MapContainer
        center={position}
        zoom={13}
        style={mapStyle}
        tap={false} // Disables the mobile tap to prevent map from hijacking page scroll
        onClick={toggleActive}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>
            {suburb}, {city}
          </Popup>
        </Marker>
      </MapContainer>
      <div className="location-description">
        <h2>{suburb}, {city}</h2>
      </div>
    </div>
  );
}

export default LocationInfo;
