// src/components/AuthModal/RegisterModal.jsx
import React, { useState } from 'react';
import { Modal, Box, TextField, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useUserSessionStore from '../../stores/userSessionStore'; // Import the store
import './RegisterModal.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  boxShadow: 24,
  color: 'black',
  p: 4,
};

function RegisterModal({ open, handleClose }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    termsAgreed: false,
  });

  const [loading, setLoading] = useState(false);

  const register = useUserSessionStore(state => state.register);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      // Strip leading and trailing spaces from form data
      const { email, password, firstName, lastName, phoneNumber, termsAgreed } = formData;
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();
      const trimmedFirstName = firstName.trim();
      const trimmedLastName = lastName.trim();
      const trimmedPhoneNumber = phoneNumber.trim();

      if (!trimmedEmail || !trimmedPassword || !trimmedFirstName || !trimmedLastName || !trimmedPhoneNumber || !termsAgreed) {
        alert("Please fill in all fields and agree to the terms.");
        setLoading(false);
        return;
      }
      const result = await register(trimmedEmail, trimmedPassword, trimmedPhoneNumber, trimmedFirstName, trimmedLastName, termsAgreed, false);
      if (!result.error) {
        setFormData({
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          termsAgreed: false,
        });
        handleClose();
      }
    } catch (error) {
      console.error("Error registering user:", error);
      alert("Error registering user.");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleRegister();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-box" sx={modalStyle}>
        <div className="modal-header">
          <Typography variant="h5">Register</Typography>
          <Button onClick={handleClose}>X</Button>
        </div>
        <div className="modal-body">
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <div className="modal-footer">
            <label>
              <input
                type="checkbox"
                name="termsAgreed"
                checked={formData.termsAgreed}
                onChange={handleChange}
              />
              Agree to terms and conditions
            </label>
            <LoadingButton
              onClick={handleRegister}
              variant="contained"
              loading={loading}
              loadingPosition="center"
            >
              Register
            </LoadingButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default RegisterModal;
