import React from 'react';
import './Footer.css'; // Ensure you create a corresponding CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h4>For Guests</h4>
                <ul>
                    <li><a href="/properties">Find Your Happy Place</a></li>
                    {/* <li><a href="#">Blog</a></li> */}
                    {/* <li><a href="#">Local</a></li> */}
                    {/* <li><a href="#">FAQs</a></li> */}
                </ul>
            </div>
            <div className="footer-section">
                <h4>For Partners</h4>
                <ul>
                    <li><a href="/property-sign-up">Sign up your villa</a></li>
                    {/* <li><a href="#">Own With NPHomes</a></li> */}
                    {/* <li><a href="#">Join the Team</a></li> */}
                </ul>
            </div>
            <div className="footer-section">
                <h4>For Everyone</h4>
                <ul>
                    {/* <li><a href="#">About NPHomes</a></li> */}
                    {/* <li><a href="#">In the Press</a></li> */}
                    <li><a href="#">Privacy Policy</a></li>
                    <li><a href="#">Terms</a></li>
                </ul>
            </div>
            <div className="social-media">
                <h4>Connect with us</h4>
                <div>
                    <a href="https://facebook.com" aria-label="Visit our Facebook page" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://twitter.com" aria-label="Follow us on Twitter" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://instagram.com" aria-label="Follow us on Instagram" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://linkedin.com" aria-label="Connect with us on LinkedIn" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 YourHappyPlace.co.za All rights reserved.</p>
                </div>
            </div>



        </footer>
    );
}

export default Footer;
