import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQSection.css';

const FAQSection = () => {
  const faqs = [
    {
      question: "How do I list my villa?",
      answer: "Simply sign up and complete the listing form with details about your property.",
    },
    {
      question: "What kind of support can I expect?",
      answer: "Our dedicated team is available to assist you with any questions or issues you may have.",
    },
    {
      question: "What are the benefits of listing my villa?",
      answer: "You will gain exposure to a global audience, benefit from our expert marketing strategies, and receive dedicated support from our team.",
    },
    {
      question: "How do you ensure the quality of the villas listed?",
      answer: "We handpick only the finest luxury villas and regularly inspect them to ensure they meet our high standards.",
    },
    {
      question: "Is there a fee for listing my villa?",
      answer: "There is no upfront fee to list your villa. We charge a commission based on bookings made through our platform.",
    },
    {
      question: "Can I update my villa's listing after it's published?",
      answer: "Yes, you can easily update your listing details at any time through your account dashboard.",
    },
    {
      question: "How do I contact customer support?",
      answer: "You can contact our customer support team via email or phone, and we'll be happy to assist you with any inquiries.",
    },
  ];

  return (
    <div className="faq-container">
      <Box className="faq-section">
        <h2 className="faq-header">
          Frequently Asked Questions
        </h2>

        {faqs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#000' }} />}>
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </div>
  );
};

export default FAQSection;
