import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Card, CardContent, Typography, Box, Button, Container, CircularProgress, Grid, IconButton } from '@mui/material';
import moment from 'moment';
import { FileCopyOutlined } from '@mui/icons-material';
import './ManageEnquiries.css';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';
import useUserSessionStore from '../../../stores/userSessionStore';

const ManageEnquiries = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const userDoc = useUserSessionStore((state) => state.userDoc);

    useDocumentTitleAndDescription('Manage Enquiries - Your Happy Place', 'View and manage enquiries');

    useEffect(() => {
        const fetchEnquiries = async () => {
            setLoading(true);
            try {
                let q;
                if (userDoc.userRole === 'admin') {
                    q = query(collection(db, 'enquiries'));
                } else if (userDoc.isOwner) {
                    q = query(collection(db, 'enquiries'), where('ownerId', '==', userDoc.userId));
                } else {
                    return; // Exit early if user is neither admin nor owner
                }
                const querySnapshot = await getDocs(q);
                const enquiriesList = await Promise.all(
                    querySnapshot.docs.map(async (docSnapshot) => {
                        const enquiryData = docSnapshot.data();
                        const propertyDoc = await getDoc(doc(db, 'properties', enquiryData.propertyId));
                        return {
                            id: docSnapshot.id,
                            ...enquiryData,
                            propertyName: propertyDoc.exists() ? propertyDoc.data().name : 'Unknown Property'
                        };
                    })
                );
                enquiriesList.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds); // Sort by createdAt
                setEnquiries(enquiriesList);
            } catch (error) {
                console.error('Error fetching enquiries: ', error);
            }
            setLoading(false);
        };

        fetchEnquiries();
    }, [userDoc]);

    const markAsResponded = async (id) => {
        try {
            const enquiryRef = doc(db, 'enquiries', id);
            await updateDoc(enquiryRef, { responded: true });
            setEnquiries(enquiries.map(enquiry => enquiry.id === id ? { ...enquiry, responded: true } : enquiry));
        } catch (error) {
            console.error('Error updating enquiry: ', error);
        }
    };

    const deleteEnquiry = async (id) => {
        try {
            // Confirm before deleting
            if (!window.confirm('Are you sure you want to delete this enquiry?')) {
                return;
            }

            await deleteDoc(doc(db, 'enquiries', id));
            setEnquiries(enquiries.filter(enquiry => enquiry.id !== id));
        } catch (error) {
            console.error('Error deleting enquiry: ', error);
        }
    };

    const createGmailLink = (enquiry) => {
        const { email, checkIn, checkOut, guestCount, propertyName, totalCost } = enquiry;
        const subject = `Response to your enquiry for ${propertyName}`;
        const body = `
            Dear ${email},

            Thank you for your enquiry regarding ${propertyName}.

            Here are the details of your enquiry:
            - Check-in: ${moment(checkIn).format('Do MMMM YYYY')}
            - Check-out: ${moment(checkOut).format('Do MMMM YYYY')}
            - Guests: ${guestCount}
            - Total Cost: R${totalCost.toLocaleString()}

            If you have any further questions, please feel free to contact us.

            Best regards,
            Your Happy Place Team
        `;
        return `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    if (loading) {
        return (
            <Container className="manage-enquiries-container">
                <Typography variant="h4" className="manage-enquiries-title">Manage Enquiries</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container className="manage-enquiries-container">
            <Typography variant="h4" className="manage-enquiries-title">Manage Enquiries</Typography>
            <Grid container spacing={3}>
                {enquiries.map(enquiry => (
                    <Grid item key={enquiry.id} xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent >
                                <Typography variant="h6" className="enquiry-card-title">
                                    {enquiry.email}
                                    <IconButton
                                        onClick={() => {
                                            navigator.clipboard.writeText(enquiry.email);
                                            alert('Email copied to clipboard');
                                        }}
                                        style={{ color: 'inherit' }}
                                    >
                                        <FileCopyOutlined />
                                    </IconButton>
                                </Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Phone:</span> {enquiry.phoneNumber}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Check-in:</span> {moment(enquiry.checkIn).format('Do MMMM YYYY')}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Check-out:</span> {moment(enquiry.checkOut).format('Do MMMM YYYY')}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Guests:</span> {enquiry.guestCount}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Nights:</span> {enquiry.nights}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Total Cost:</span> R{enquiry.totalCost.toLocaleString()}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Property Name:</span> {enquiry.propertyName}</Typography>
                                <Typography variant="body2" className="enquiry-card-info"><span>Enquired At:</span> {moment(enquiry.createdAt.toDate()).format('Do MMMM YYYY, h:mm:ss a')}</Typography>
                                <Box className="enquiry-card-actions">
                                    <Button
                                        variant="contained"
                                        onClick={() => markAsResponded(enquiry.id)}
                                        disabled={enquiry.responded}
                                    >
                                        {enquiry.responded ? 'Responded' : 'Mark as Responded'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="delete-button"
                                        onClick={() => deleteEnquiry(enquiry.id)}
                                    >
                                        Delete Enquiry
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="respond-button"
                                        href={createGmailLink(enquiry)}
                                        target="_blank" // Open Gmail in a new tab
                                    >
                                        Respond to Enquiry
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ManageEnquiries;
