// SearchBar.js
import React, { useEffect } from 'react';
import useFiltersStore from '../../../../stores/useFiltersStore';
import './SearchBar.css';

function SearchBar() {
  const setSearchTerm = useFiltersStore(state => state.setSearchTerm);
  const setFilter = useFiltersStore(state => state.setFilter);
  
  useEffect(() => {    
    return () => {
      setSearchTerm('');
    }
}, []);


  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm.length === 1) {
      setFilter('');
    }
    setSearchTerm(searchTerm);
  }

  return (
    <div className="hero">
      <h1>Your holiday your way</h1>
      <input
        type="text"
        placeholder="Search"
        onChange={handleSearchChange}
      />
    </div>
  );
}

export default SearchBar;
