import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase'; // Ensure your Firebase configuration is correct
import { collection, query, where, getDocs } from 'firebase/firestore';

import PropertyCalendarDesktop from './Calendar/PropertyCalendarDesktop/PropertyCalendarDesktop';
import PropertyInfo from './PropertyInfo';
import './PropertyPage.css';
import PropertyReserveButton from './PropertyReserveButton';

// Components
import Footer from '../../Footer/Footer';
import TitleSection from './TitleSection/TitleSection';

import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';


function PropertyPage() {
    const { slug } = useParams();
    const [property, setProperty] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useDocumentTitleAndDescription(property?.name, property?.description)

    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const propertiesCollection = collection(db, 'properties');
                const q = query(propertiesCollection, where('slug', '==', slug));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const propertyDoc = querySnapshot.docs[0];
                    setProperty({ id: propertyDoc.id, ...propertyDoc.data() });
                } else {
                    console.error('No such property!');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching property:', error);
                setLoading(false);
            }
        };

        fetchProperty();
    }, [slug]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) {
        return <div className='loading-container'>Loading...</div>;
    }

    if (!property) {
        return <div>No property found</div>;
    }

    return (
        <>
            <div className="property-page-container">
                <div className="property-cover-image" style={{ backgroundImage: `url(${property.photos?.coverPhoto?.url})` }}>
                    <TitleSection name={property.name} details={property} />
                </div>
                <div className="info-columns">
                    <div className='info-column'>
                        <PropertyInfo details={property} />
                    </div>
                    {!isMobile && <PropertyCalendarDesktop lodgeSlug={property.slug} />}
                </div>
                <PropertyReserveButton lodgeSlug={property.slug} />
            </div>
            <Footer />
        </>
    );
}

export default PropertyPage;
