import React from 'react';
import './StepSection.css';

const steps = [
  {
    step: 'Step 1',
    title: 'Sign Up and Create Your Listing',
    description: 'Register and provide details about your property to create your listing.',
  },
  {
    step: 'Step 2',
    title: 'Set Your Availability and Pricing (Coming Soon)',
    description: 'Choose when your property is available and set competitive pricing.',
  },
  {
    step: 'Step 3',
    title: 'Let Us Handle the Marketing',
    description: 'We will market your property to a global audience to maximize bookings.',
  },
  {
    step: 'Step 4',
    title: 'Receive Bookings and Earn Income',
    description: 'Start receiving bookings and earning income from your property.',
  },
];

const StepSection = () => {
  return (
    <div className="step-section">
      <h2 className="step-header">How It Works</h2>
      <div className="step-container">
        {steps.map((step, index) => (
          <div
            key={index}
            className="step-box"
          >
            <h3>{step.step}</h3>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepSection;
