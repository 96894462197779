import React from 'react';
import { FormControl, FormGroup, FormLabel, FormControlLabel, Checkbox, FormHelperText, Paper, Grid, TextField } from '@mui/material';

const CheckboxGroup = ({ category, amenities, formData, handleCheckboxChange, handleCommentChange }) => (
    <Grid item xs={12}>
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <FormControl component="fieldset">
                <h2>{category}</h2>
                <FormGroup>
                    {amenities.map((option) => (
                        <React.Fragment key={option.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={category.toLowerCase().replace(/ /g, '_')}
                                        value={option.id}
                                        checked={formData[category.toLowerCase().replace(/ /g, '_')]?.includes(option.id)}
                                        onChange={(e) => handleCheckboxChange(e, category.toLowerCase().replace(/ /g, '_'))}
                                    />
                                }
                                label={option.name}
                            />
                            <FormHelperText>{option.description}</FormHelperText>
                        </React.Fragment>
                    ))}
                </FormGroup>
            </FormControl>
            <TextField
                fullWidth
                label={`${category} Comments`}
                name={`${category.toLowerCase().replace(/ /g, '_')}Comments`}
                value={formData[`${category.toLowerCase().replace(/ /g, '_')}Comments`]}
                onChange={(e) => handleCommentChange(e, `${category.toLowerCase().replace(/ /g, '_')}`)}
                multiline
                rows={2}
                variant="filled"
                sx={{ mt: 2 }} // Adding margin top for spacing
            />
        </Paper>
    </Grid>
);

export default CheckboxGroup;
