import React from 'react';
import './WhyPartnerSection.css';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedIcon from '@mui/icons-material/Verified';

const WhyPartnerSection = () => {
  const features = [
    { icon: <HomeIcon className="feature-icon" />, title: "Exposure to a Global Audience", description: "Reach high-end travelers from around the world." },
    { icon: <PeopleIcon className="feature-icon" />, title: "Expert Marketing", description: "Benefit from our professional marketing strategies designed to showcase your villa." },
    { icon: <SecurityIcon className="feature-icon" />, title: "Dedicated Support", description: "Receive personalized assistance from our experienced team." },
    { icon: <VerifiedIcon className="feature-icon" />, title: "Enhanced Security", description: "Gain peace of mind with our secure booking platform." },
  ];

  return (
    <div className="why-partner-section">
      <h2>Why Partner with Your Happy Place?</h2>
      <div className="feature-box-container">
        {features.map((feature, index) => (
          <div key={index} className="feature-box">
            {feature.icon}
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhyPartnerSection;
