import { Card, CardContent, CardMedia, CircularProgress, Container, Grid, Typography, Box, Button } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import useUserSessionStore from '../../../stores/userSessionStore';
import { Link } from 'react-router-dom';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';

const PropertySelection = () => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const userDoc = useUserSessionStore((state) => state.userDoc);

    useDocumentTitleAndDescription('Manage Properties - Your Happy Place', 'Select a property to manage');

    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true);
            try {
                let q;
                if (userDoc.userRole === 'admin') {
                    q = query(collection(db, 'properties'));
                } else if (userDoc.isOwner) {
                    q = query(collection(db, 'properties'), where('owners', 'array-contains', userDoc.userId));
                } else {
                    return; // Exit early if user is neither admin nor owner
                }
                const querySnapshot = await getDocs(q);
                const propertiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProperties(propertiesList);
            } catch (error) {
                console.error('Error fetching properties: ', error);
            }
            setLoading(false);
        };
    
        fetchProperties();
    }, [userDoc]);
    

    if (loading) {
        return (
            <Container>
                <Typography variant="h4" gutterBottom>Properties</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Properties</Typography>
            <Grid container spacing={3}>
                {properties.map(property => (
                    <Grid item key={property.id} xs={12} sm={6} md={4}>
                        <Card>
                            {property.photos?.coverPhoto && (
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={property.photos.coverPhoto.url}
                                    alt={`${property.name} cover`}
                                />
                            )}
                            <CardContent>
                                <Typography variant="h5">{property.name}</Typography>
                                <Typography variant="body2">{property.city}</Typography>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={`/edit-property/${property.slug}`}
                                    >
                                        Edit Property Details
                                    </Button>
                                </Box>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={`/property-photos-upload/${property.slug}`}
                                    >
                                        Upload Photos
                                    </Button>
                                </Box>
                                <Box mt={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={`/property-photo-metadata/${property.slug}`}
                                    >
                                        Add Photo Metadata
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default PropertySelection;
