// useFiltersStore.js
import { create } from 'zustand'

const useFiltersStore = create(set => ({
  filter: '',
  searchTerm: '',
  setFilter: filter => set({ filter }),
  setSearchTerm: searchTerm => set({ searchTerm }),
}));

export default useFiltersStore;
