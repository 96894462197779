import React from 'react';
import { FormControl, FormGroup, FormLabel, FormControlLabel, Checkbox, FormHelperText, Paper, Grid, TextField } from '@mui/material';

const BedroomCheckboxGroup = ({ bedroomIndex, amenities, formData, handleCheckboxChange, handleCommentChange }) => (
    <Grid item xs={12} sm={6}>
        <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <FormControl component="fieldset">
                <h4>Bedroom {bedroomIndex}</h4>
                <FormGroup>
                    {amenities.map((option) => (
                        <React.Fragment key={option.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={`bedroom_${bedroomIndex}`}
                                        value={option.id}
                                        checked={formData.bedrooms[bedroomIndex]?.includes(option.id)}
                                        onChange={(e) => handleCheckboxChange(e, 'bedrooms', bedroomIndex)}
                                    />
                                }
                                label={option.name}
                            />
                            <FormHelperText>{option.description}</FormHelperText>
                        </React.Fragment>
                    ))}
                </FormGroup>
            </FormControl>
        </Paper>
    </Grid>
);

export default BedroomCheckboxGroup;
