// PaymentForm.js
import React, { useState } from 'react';
import TermsModal from '../TermsModal/TermsModal';
import './PaymentForm.css';
import { Box, TextField, Typography } from '@mui/material';

function PaymentForm({ setEmail, setPhoneNumber, email, phoneNumber, property }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="payment-form-container">
      {/* <h2>Pay for your trip</h2> */}
      {/* <div className="payment-form">
        <input type="text" placeholder="Card number" />
        <input type="text" placeholder="Expiration (MM / YY)" />
        <input type="text" placeholder="CVC" />
        <input type="text" placeholder="Country" />
        <button>Google Pay</button>
        <a href="#coupon">Got a coupon?</a>
      </div> */}
      <div className="contact-information">
        <h3>Contact information</h3>
        <Box component="form" sx={{ mt: 3 }}>
          {/* <Typography variant="h6">Enter your details</Typography> */}
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            variant="outlined"
            sx={{ mt: 3 }} // Adding margin top for spacing
          />

          <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            margin="normal"
            variant="outlined"
          />
        </Box>
      </div>
      <div className="rules-agreements">
        <h3>Rules & agreements</h3>
        <p>I accept the terms and conditions to the booking rules and GDPR.</p>
        <a href="#rules" onClick={openModal}>Booking rules</a>
      </div>
      <TermsModal isOpen={isModalOpen} onClose={closeModal} property={property} />
    </div>
  );
}

export default PaymentForm;
