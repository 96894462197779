import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const SuccessPage = () => {
    return (
        <Container>
            <Box textAlign="center" mt={5}>
                <Typography variant="h4" gutterBottom>
                    🎉 Property Submitted Successfully! 🎉
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Thank you for submitting your property. We will review the details and get back to you shortly.
                </Typography>
                <Box mt={3}>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button variant="contained" color="primary">
                            Go to Home
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

export default SuccessPage;
