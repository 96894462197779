// src/components/AddPhotoMetaData/AddPhotoMetaData.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Container, Typography, TextField, Box, Button, CircularProgress } from '@mui/material';
import './AddPhotoMetaData.css';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';

const AddPhotoMetaData = () => {
    const { slug } = useParams();
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [propertyData, setPropertyData] = useState(null);

    useDocumentTitleAndDescription(`Add Photo MetaData: ${propertyData?.name}`, 'Add descriptions to photos');

    useEffect(() => {
        const fetchPhotos = async () => {
            setLoading(true);
            try {
                // Query to find the document with the matching slug
                const propertiesCollection = collection(db, 'properties');
                const propertyQuery = query(propertiesCollection, where('slug', '==', slug));
                const propertySnapshot = await getDocs(propertyQuery);

                if (!propertySnapshot.empty) {
                    const propertyDoc = propertySnapshot.docs[0];
                    const propertyData = propertyDoc.data();
                    setPropertyData(propertyData);

                    const photosList = [];

                    if (propertyData.photos) {
                        const addToPhotosList = (photos, category) => {
                            Object.entries(photos).forEach(([key, value]) => {
                                photosList.push({
                                    id: `${category}_${key}`,
                                    url: value.url,
                                    description: value.description || '',
                                    category,
                                });
                            });
                        };

                        if (propertyData.photos.coverPhoto) {
                            photosList.push({
                                id: 'coverPhoto',
                                url: propertyData.photos.coverPhoto.url,
                                description: propertyData.photos.coverPhoto.description || '',
                                category: 'coverPhoto',
                            });
                        }

                        if (propertyData.photos.interiorPhotos) {
                            addToPhotosList(propertyData.photos.interiorPhotos, 'interiorPhotos');
                        }

                        if (propertyData.photos.exteriorPhotos) {
                            addToPhotosList(propertyData.photos.exteriorPhotos, 'exteriorPhotos');
                        }

                        if (propertyData.photos.kitchenPhotos) {
                            addToPhotosList(propertyData.photos.kitchenPhotos, 'kitchenPhotos');
                        }

                        if (propertyData.photos.locationPhotos) {
                            addToPhotosList(propertyData.photos.locationPhotos, 'locationPhotos');
                        }

                        if (propertyData.photos.bedrooms) {
                            Object.entries(propertyData.photos.bedrooms).forEach(([bedroomKey, bedroomPhotos]) => {
                                addToPhotosList(bedroomPhotos, `bedrooms_${bedroomKey}`);
                            });
                        }
                    }

                    setPhotos(photosList);
                } else {
                    console.error('No property found with the given slug.');
                }
            } catch (error) {
                console.error('Error fetching photos: ', error);
            }
            setLoading(false);
        };

        fetchPhotos();
    }, [slug]);

    const handleDescriptionChange = (id, description) => {
        setPhotos(photos.map(photo => photo.id === id ? { ...photo, description } : photo));
    };

    const handleSaveDescriptions = async () => {
        setSaving(true);
        try {
            const propertyRef = doc(db, 'properties', propertyData.propertyId);
            const propertyDoc = await getDoc(propertyRef);
            if (propertyDoc.exists()) {
                const property = propertyDoc.data();
                const updatedPhotos = { ...property.photos };

                photos.forEach(photo => {
                    const { id, description } = photo;
                    const [category, ...rest] = id.split('_');
                    const key = rest.join('_');  // Combine the rest to handle keys with underscores

                    if (category === 'coverPhoto') {
                        updatedPhotos.coverPhoto.description = description;
                    } else if (updatedPhotos[category] && updatedPhotos[category][key]) {
                        updatedPhotos[category][key].description = description;
                    } else if (category.startsWith('bedrooms')) {
                        const bedroomKey = category.split('_')[1];
                        if (updatedPhotos.bedrooms[bedroomKey] && updatedPhotos.bedrooms[bedroomKey][key]) {
                            updatedPhotos.bedrooms[bedroomKey][key].description = description;
                        }
                    }
                });

                await updateDoc(propertyRef, { photos: updatedPhotos, updatedAt: new Date() });
                alert('Descriptions saved successfully');
            } else {
                console.error('No property found with the given slug.');
            }
        } catch (error) {
            console.error('Error saving descriptions:', error);
            alert('Error saving descriptions');
        }
        setSaving(false);
    };


    if (loading) {
        return (
            <Container>
                <Typography variant="h4" gutterBottom>Photo Descriptions</Typography>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    const categories = Array.from(new Set(photos.map(photo => photo.category).filter(Boolean)));
    const categoryNames = {
        coverPhoto: 'Cover Photo',
        interiorPhotos: 'Interior Photos',
        exteriorPhotos: 'Exterior Photos',
        kitchenPhotos: 'Kitchen Photos',
        locationPhotos: 'Location Photos',
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Photo Descriptions</Typography>
            {categories.map(category => (
                <Box key={category} mb={4}>
                    <Typography variant="h5" gutterBottom>
                        {categoryNames[category] || category.replace(/_/g, ' ')}
                    </Typography>
                    {photos.filter(photo => photo.category === category).map(photo => (
                        <Box key={photo.id} mb={3}>
                            <img src={photo.url} alt={photo.id} style={{ width: '200px', height: 'auto' }} />
                            <TextField
                                label="Description"
                                fullWidth
                                value={photo.description || ''}
                                onChange={(e) => handleDescriptionChange(photo.id, e.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </Box>
                    ))}
                </Box>
            ))}
            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveDescriptions}
                disabled={saving}
            >
                {saving ? <CircularProgress size={24} /> : 'Save Descriptions'}
            </Button>
        </Container>
    );
};

export default AddPhotoMetaData;
