import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './LandingPageHeroSection.css';
import logo from '../../../../assets/Logo.jpeg';

function LandingPageHeroSection({ header, subheader, description, buttonText, backgroundImage, onButtonClick, height = '75vh' }) {
  // Check if the backgroundImage is a URL or a local path
  const isUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <Box
      className="hero-section"
      sx={{
        backgroundImage: isUrl(backgroundImage) ? `url(${backgroundImage})` : `url(${require(`${backgroundImage}`)})`,
        minHeight: height, // Use minHeight instead of height
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        border: 'none',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))',
          zIndex: 1,
        }}
      />
      <Box sx={{ position: 'relative', zIndex: 2, maxWidth: 1000, padding: 2, marginTop: '60px', }}>
        <Typography variant="h6" component="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
          {subheader}
        </Typography>
        <Typography variant="h3" component="h3" gutterBottom sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } }}>
          {header}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          className="hero-description" // Add a class for description
          sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}
        >
          {description}
        </Typography>
        <Box display="flex" justifyContent="center" m={5} >
          <img src={logo} alt="Logo" style={{ maxHeight: '150px' }} />
        </Box>
        <Button
          variant="contained"
          sx={{
            mt: 2,
            fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
          }}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}

export default LandingPageHeroSection;
