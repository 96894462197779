// src/components/AuthModal/LoginModal.jsx
import React, { useState } from 'react';
import { Modal, Box, TextField, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useUserSessionStore from '../../stores/userSessionStore'; // Import the store
import './LoginModal.css'; // Adjust the import path accordingly
import { auth } from '../../firebase'; // Import the firebase auth

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  boxShadow: 24,
  color: 'black',
  p: 4,
};

function LoginModal({ open, handleClose, openRegisterModal }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);

  const login = useUserSessionStore((state) => state.login);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const { email, password } = formData;
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();

      if (!trimmedEmail || !trimmedPassword) {
        alert("Please fill in all fields.");
        setLoading(false);
        return;
      }

      await login(trimmedEmail, trimmedPassword);

      if (auth.currentUser === null) {
        setLoading(false);
        return;
      }

      setFormData({
        email: '',
        password: '',
      });

      handleClose();
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Error logging in.");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modal-box" sx={modalStyle}>
        <div className="modal-header">
          <Typography variant="h5">Login</Typography>
          <Button onClick={handleClose}>X</Button>
        </div>
        <div className="modal-body">
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            onKeyDown={handleKeyDown}
          />
          <div className="modal-footer">
            <LoadingButton
              onClick={handleLogin}
              variant="contained"
              loading={loading}
              loadingPosition="center"
            >
              Login
            </LoadingButton>
            <Button onClick={openRegisterModal} variant="outlined" style={{ marginLeft: '8px' }}>
              Register
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default LoginModal;
