export const propertyRules = [
  {
    header: "Convenient check-in & out times",
    description: ({ checkInTimeEarliest, checkInTimeLatest, checkOutTime }) =>
      `Check-in time: ${checkInTimeEarliest}. Check-out time: ${checkOutTime}. Check-in runs until ${checkInTimeLatest}. Check-in’s after ${checkInTimeLatest} can be arranged directly with the property in advance.`
  },
  {
    header: "Check-in procedure",
    description: ({ checkInProcedure }) => `Check-in procedure: ${checkInProcedure}.`
  },
  {
    header: "Maximum occupancy",
    description: "This home follows a strict maximum occupancy rule. Please make sure that the total number of guests accompanying you (including both children and adults) doesn’t exceed the indicated maximum occupancy above to avoid cancellation of your stay."
  },
  {
    header: "Smoking policy",
    description: ({ smokingPolicy }) => `${smokingPolicy ? 'Smoking allowed' : 'No smoking'}.`
  },
  {
    header: "No parties or events",
    description: ({ allowsParties }) => allowsParties ? "Parties or events are allowed." : "No parties or events."
  },
  {
    header: "Pets",
    description: ({ allowsPets }) => allowsPets ? "Pets are allowed." : "Pets are not allowed."
  },
  {
    header: "Noise policy",
    description: ({ allowsLateNoise }) => allowsLateNoise ? "We allow loud noise after 10pm." : "No Loud Noise after hours - Please kindly be mindful to our home and our wonderful neighbors."
  },
  {
    header: "Breakages",
    description: "Please report any breakages to the Property directly."
  }
];