import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBath, faUsers } from '@fortawesome/free-solid-svg-icons';
import './TitleSection.css';


function TitleSection({ name, details }) {

    return (
        <div className="title-section">
            <div className="location">{details.address?.suburb}, {details.address?.city}</div>
            <h1>{name}</h1>
            <div className="details">
                <span><FontAwesomeIcon icon={faBed} /> {details.bedroomCount} bedrooms</span>
                <span><FontAwesomeIcon icon={faBath} /> {details.bathroomCount} bathrooms</span>
                {/* <span><FontAwesomeIcon icon={faBed} /> {details.beds ? details.beds.length : 0} beds</span> */}
                <span><FontAwesomeIcon icon={faUsers} /> {details.maxGuestCount} guests</span>
            </div>
        </div>
    );
}

export default TitleSection;
