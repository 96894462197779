import React from 'react';
import './TripHeader.css';

function TripHeader({ propertyName, views }) {
  return (
    <div className="trip-header">
      <h1>{propertyName}</h1>
      <p>{views} views in the last 24 hours</p>
    </div>
  );
}

export default TripHeader;
