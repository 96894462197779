import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getCountFromServer } from 'firebase/firestore';
import './Ticker.css';

const Ticker = ({ message }) => {
  const [propertyCount, setPropertyCount] = useState(null); // Use null to indicate loading

  useEffect(() => {
    const fetchPropertyCount = async () => {
      try {
        const db = getFirestore();
        const coll = collection(db, 'properties');
        const snapshot = await getCountFromServer(coll);
        setPropertyCount(snapshot.data().count);
      } catch (error) {
        console.error('Error fetching property count:', error);
      }
    };

    fetchPropertyCount();
  }, []);

  return (
    <div className="ticker">
      <span>
        {`Launching Spring of 2024 - `}
        {propertyCount !== null ? `${propertyCount} villas signed up` : `Loading...`}
      </span>
    </div>
  );
};

export default Ticker;
