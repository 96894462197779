import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import { collection, getDocs, setDoc, doc, updateDoc, where, query } from 'firebase/firestore';
import {
    Container,
    Typography,
    Box,
    Grid,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    Checkbox,
    FormGroup,
    Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextFieldComponent from './TextFieldComponent/TextFieldComponent';
import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import BedroomCheckboxGroup from './BedroomCheckboxGroup/BedroomCheckboxGroup';
import MapComponent from './MapComponent/MapComponent'; // Import the new MapComponent
import LoginModal from '../../AuthModal/LoginModal'; // Import the LoginModal
import RegisterModal from '../../AuthModal/RegisterModal'; // Import the RegisterModal
import './PropertySignUp.css';
import useUserSessionStore from '../../../stores/userSessionStore';

// Import the logo
import logo from '../../../assets/Logo.jpeg';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';

const PropertySignUp = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const isEditing = !!slug; // Determine if we are editing based on the presence of a slug in the URL

    const [propertyId, setPropertyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

    const user = useUserSessionStore((state) => state.user); // Get the user from the store
    const userDoc = useUserSessionStore((state) => state.userDoc); // Get the userDoc from the store

    useEffect(() => {
        if (!user) {
            setIsLoginModalOpen(true);
        }
    }, [user]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useDocumentTitleAndDescription(isEditing ? 'Edit Property - Your Happy Place' : 'Home Signup 2024', isEditing ? 'Edit your property details' : 'Sign up your property with us');

    const [formData, setFormData] = useState({
        name: '',
        unitNumber: '',
        streetName: '',
        suburb: '',
        city: '',
        zipcode: '',
        country: '',
        email: '',
        phoneNumber: '',
        description: '',
        backup_electricity: [],
        backup_electricityComments: '',
        bedroomCount: '',
        bedrooms: {},
        bathroomCount: '',
        maxGuestCount: '',
        bedroomsComments: '',
        interior: [],
        interiorComments: '',
        kitchen: [],
        kitchenComments: '',
        exterior: [],
        exteriorComments: '',
        cleaning: [],
        cleaningComments: '',
        location: [],
        locationComments: '',
        checkInTimeEarliest: '14:00',
        checkInTimeLatest: '20:00',
        checkOutTime: '10:00',
        priceFrom: '',
        priceTo: '',
        suggestedCategories: '',
        activitiesOffered: '',
        activitiesCost: '',
        additionalInfo: '',
        public: false,
        checkInProcedure: 'Self Check in with Lockbox',
        customCheckInProcedure: '',
        allowsSmoking: false,
        allowsParties: false,
        additionalCosts: '',
        allowsPets: false,
        allowsLateNoise: false,
    });


    const [amenities, setAmenities] = useState({
        'Backup Electricity': [],
        'Interior': [],
        'Exterior': [],
        'Kitchen': [],
        'Cleaning': [],
        'Location': [],
        'Bedroom': [],
    });

    const [bedroomCount, setBedroomCount] = useState(0);

    useEffect(() => {
        const fetchAmenities = async () => {
            const querySnapshot = await getDocs(collection(db, 'amenities'));
            const amenitiesData = {
                'Backup Electricity': [],
                'Interior': [],
                'Exterior': [],
                'Kitchen': [],
                'Cleaning': [],
                'Location': [],
                'Bedroom': [],
            };
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (amenitiesData[data.category]) {
                    amenitiesData[data.category].push({ id: doc.id, name: data.name, description: data.description });
                }
            });
            setAmenities(amenitiesData);
        };

        fetchAmenities();
    }, []);

    useEffect(() => {
        const fetchProperty = async () => {
            if (isEditing) {
                setLoading(true);
                try {
                    const propertiesCollection = collection(db, 'properties');
                    const q = query(propertiesCollection, where('slug', '==', slug));
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        const propertySnap = querySnapshot.docs[0];
                        const propertyData = propertySnap.data();
                        console.log('Property data:', propertyData);
                        setFormData({
                            ...propertyData,
                            ...propertyData.rules,
                            bedrooms: propertyData.bedrooms || {},
                        });
                        setBedroomCount(Object.keys(propertyData.bedrooms || {}).length);
                        setPropertyId(propertySnap.id);
                    } else {
                        console.error('No such property!');
                    }
                } catch (error) {
                    console.error('Error fetching property:', error);
                }
                setLoading(false);
            }
        };

        fetchProperty();
    }, [isEditing, slug]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'priceFrom' || name === 'priceTo' ? parseFloat(value) : value,
        });
    };

    const handleCheckboxChange = (e, category, roomIndex = null) => {
        const { value, checked } = e.target;
        let updatedValues;

        if (category === 'bedrooms' && roomIndex !== null) {
            updatedValues = { ...formData.bedrooms };
            const roomAmenities = updatedValues[roomIndex] || [];
            if (checked) {
                roomAmenities.push(value);
            } else {
                const index = roomAmenities.indexOf(value);
                if (index > -1) {
                    roomAmenities.splice(index, 1);
                }
            }
            updatedValues[roomIndex] = roomAmenities;
        } else {
            updatedValues = [...formData[category]];
            if (checked) {
                updatedValues.push(value);
            } else {
                updatedValues = updatedValues.filter((item) => item !== value);
            }
        }

        setFormData({
            ...formData,
            [category]: updatedValues,
        });
    };

    const handleCommentChange = (e, category) => {
        const { value, name } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [`${category}Comments`]: value,
            rules: {
                ...prevFormData.rules,
                [name]: value
            }
        }));
        console.log(`${category}Comments`, value);
    };

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            checkInProcedure: value,
            customCheckInProcedure: value === 'Custom' ? formData.customCheckInProcedure : '',
        });
    };

    const handleCheckBoxToggle = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };

    const sanitizeString = (str) => {
        return str.trim().toLowerCase().replace(/[\s\W_]+/g, '_');
    };

    const generateDocId = () => {
        const { name, suburb, city, country } = formData;
        const formattedName = sanitizeString(name);
        const formattedSuburb = sanitizeString(suburb);
        const formattedCity = sanitizeString(city);
        const formattedCountry = sanitizeString(country);
        return `${formattedName}_${formattedSuburb}_${formattedCity}_${formattedCountry}`;
    };

    const generateSlug = () => {
        const { name } = formData;
        return sanitizeString(name).replace(/_/g, '-');
    };

    const checkIfNameExists = async (name) => {
        const propertiesCollection = collection(db, 'properties');
        const q = query(propertiesCollection, where('name_lowercase', '==', name.toLowerCase()));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    const updateIsOwner = async (uid) => {
        const userRef = doc(db, 'users', uid);
        await updateDoc(userRef, { isOwner: true });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!isEditing && await checkIfNameExists(formData.name)) {
            alert('Property name already exists. Please choose a different name.');
            setLoading(false);
            return;
        }

        const docId = isEditing ?
            propertyId
            : generateDocId();

        const rules = {
            checkInTimeEarliest: formData.checkInTimeEarliest,
            checkInTimeLatest: formData.checkInTimeLatest,
            checkOutTime: formData.checkOutTime,
            checkInProcedure: formData.checkInProcedure === 'Custom' ? formData.customCheckInProcedure : formData.checkInProcedure,
            allowsSmoking: formData.allowsSmoking,
            allowsParties: formData.allowsParties,
            additionalCosts: formData.additionalCosts,
            allowsPets: formData.allowsPets,
            allowsLateNoise: formData.allowsLateNoise,
        };

        const propertyData = {
            ...formData,
            rules,
            name_lowercase: formData.name.toLowerCase(), // Add this line
            slug: generateSlug(),
            address: {
                unitNumber: formData.unitNumber,
                streetName: formData.streetName,
                suburb: formData.suburb,
                city: formData.city,
                zipcode: formData.zipcode,
                country: formData.country,
            },
            owners: [user.uid],
            propertyId: docId,
            updatedAt: new Date(),
        };

        try {
            const propertyRef = doc(db, 'properties', docId);
            if (isEditing) {
                await updateDoc(propertyRef, propertyData);
                setLoading(false);
                navigate('/edit-property/' + propertyData.slug)
                alert('Property updated successfully.');
            } else {
                await setDoc(propertyRef, propertyData);
                await updateIsOwner(user.uid);
                navigate('/success');
            }
            console.log('Document written with ID: ', docId);
        } catch (e) {
            console.error('Error adding document: ', e);
            alert('Error submitting property.');
            setLoading(false);
        }
    };

    const handleBedroomCountChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setBedroomCount(count);
        const bedrooms = {};
        for (let i = 1; i <= count; i++) {
            bedrooms[i] = formData.bedrooms[i] || [];
        }
        setFormData({
            ...formData,
            bedrooms,
            bedroomCount: count,
        });
    };

    const handleCountChange = (e, roomType) => {
        const count = parseInt(e.target.value, 10);
        setFormData({
            ...formData,
            [`${roomType}Count`]: count,
        });
    };

    const handleLocationSelect = (location) => {
        setFormData({
            ...formData,
            locationCoordinates: location,
        });
    };

    return (
        <>
            {!user ? (
                <>
                    <Alert severity="warning" style={{ marginBottom: '20px', marginTop: '20px' }}>
                        You must be logged in to add a villa.
                    </Alert>
                    <LoginModal
                        open={isLoginModalOpen}
                        handleClose={() => setIsLoginModalOpen(false)}
                        openRegisterModal={() => {
                            setIsLoginModalOpen(false);
                            setIsRegisterModalOpen(true);
                        }}
                    />
                </>
            ) : (
                <Container className="property-signup">
                    <Box display="flex" justifyContent="center" mt={2}>
                        <img src={logo} alt="Logo" style={{ maxHeight: '150px' }} />
                    </Box>
                    <Typography variant="h4" align="center" gutterBottom>
                        {isEditing ? 'Edit Property' : 'Home Signup 2024'}
                    </Typography>
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={3}>
                            <TextFieldComponent label="Villa Name" name="name" value={formData.name || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Your Email Address" name="email" value={formData.email || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Your Phone Number" name="phoneNumber" value={formData.phoneNumber || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Tell us more about your Holiday Home" name="description" value={formData.description || ""} onChange={handleChange} required multiline rows={4} />
                            <TextFieldComponent label="Number of Bedrooms" name="bedroomCount" value={formData.bedroomCount || ""} onChange={handleBedroomCountChange} required type="number" />
                            <TextFieldComponent label="Number of Bathrooms" name="bathroomCount" value={formData.bathroomCount || ""} onChange={(e) => handleCountChange(e, 'bathroom')} required type="number" />
                            <TextFieldComponent label="Max guests" name="maxGuestCount" value={formData.maxGuestCount || ""} onChange={(e) => handleCountChange(e, 'maxGuest')} required type="number" />

                            {Object.keys(formData.bedrooms).map((roomIndex) => (
                                <BedroomCheckboxGroup
                                    key={roomIndex}
                                    bedroomIndex={roomIndex}
                                    amenities={amenities['Bedroom']}
                                    formData={formData}
                                    handleCheckboxChange={handleCheckboxChange}
                                />
                            ))}

                            <TextFieldComponent label="Bedroom comments" name="bedroomsComments" value={formData.bedroomsComments || ""} onChange={handleChange} multiline rows={2} />

                            {Object.keys(amenities).filter(category => category !== 'Bedroom').map((category) => (
                                <CheckboxGroup
                                    key={category}
                                    category={category}
                                    amenities={amenities[category]}
                                    formData={formData}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleCommentChange={handleCommentChange}
                                />
                            ))}

                            <Grid item xs={12}>
                                <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
                                    <Typography variant="h6">Rules</Typography>
                                    <TextFieldComponent label="Earliest Check-In Time" name="checkInTimeEarliest" value={formData.checkInTimeEarliest || ""} onChange={handleChange} type="time" />
                                    <TextFieldComponent label="Latest Check-In Time" name="checkInTimeLatest" value={formData.checkInTimeLatest || ""} onChange={handleChange} type="time" />
                                    <TextFieldComponent label="Check-Out Time" name="checkOutTime" value={formData.checkOutTime || ""} onChange={handleChange} type="time" />
                                    <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                                        <Typography component="legend">Check-In Procedure</Typography>
                                        <RadioGroup name="checkInProcedure" value={formData.checkInProcedure || ""} onChange={handleRadioChange}>
                                            <FormControlLabel value="Self Check in with Lockbox" control={<Radio />} label="Self Check in with Lockbox" />
                                            <FormControlLabel value="Manager to meet guests at property" control={<Radio />} label="Manager to meet guests at property" />
                                            <FormControlLabel value="Custom" control={<Radio />} label="Custom" />
                                        </RadioGroup>
                                        {formData.checkInProcedure === 'Custom' && (
                                            <TextFieldComponent label="Custom Check-In Procedure" name="customCheckInProcedure" value={formData.customCheckInProcedure || ""} onChange={handleChange} multiline rows={2} />
                                        )}
                                    </FormControl>
                                    <FormGroup row style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.allowsSmoking || false} onChange={handleCheckBoxToggle} name="allowsSmoking" />}
                                            label="Allows Smoking"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.allowsPets || false} onChange={handleCheckBoxToggle} name="allowsPets" />}
                                            label="Allows Pets"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.allowsParties || false} onChange={handleCheckBoxToggle} name="allowsParties" />}
                                            label="Allows Parties"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.allowsLateNoise || false} onChange={handleCheckBoxToggle} name="allowsLateNoise" />}
                                            label="Allows Noise after 10pm"
                                        />
                                    </FormGroup>
                                    <TextFieldComponent label="Additional Costs" name="additionalCosts" value={formData.additionalCosts || ""} onChange={handleChange} multiline rows={2} />
                                </Paper>
                            </Grid>



                            <TextFieldComponent label="Price From" name="priceFrom" value={formData.priceFrom || ""} onChange={handleChange} type="number" />
                            <TextFieldComponent label="Price To" name="priceTo" value={formData.priceTo || ""} onChange={handleChange} type="number" />
                            <TextFieldComponent label="Suggested Categories" name="suggestedCategories" value={formData.suggestedCategories || ""} onChange={handleChange} multiline rows={2} />
                            <TextFieldComponent label="Activities Offered" name="activitiesOffered" value={formData.activitiesOffered || ""} onChange={handleChange} multiline rows={4} />
                            <TextFieldComponent label="Activities Cost" name="activitiesCost" value={formData.activitiesCost || ""} onChange={handleChange} multiline rows={4} />
                            <TextFieldComponent label="Additional Information" name="additionalInfo" value={formData.additionalInfo || ""} onChange={handleChange} multiline rows={4} />

                            <TextFieldComponent label="Unit Number" name="unitNumber" value={formData.unitNumber || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Street Name" name="streetName" value={formData.streetName || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Suburb" name="suburb" value={formData.suburb || ""} onChange={handleChange} required />
                            <TextFieldComponent label="City" name="city" value={formData.city || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Zipcode" name="zipcode" value={formData.zipcode || ""} onChange={handleChange} required />
                            <TextFieldComponent label="Country" name="country" value={formData.country || ""} onChange={handleChange} required />

                            {isEditing && userDoc?.userRole === 'admin' && (
                                <Grid item xs={12}>
                                    <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
                                        <FormControl component="fieldset">
                                            <Typography component="legend">Public</Typography>
                                            <RadioGroup
                                                name="public"
                                                value={formData.public ? 'true' : 'false'}
                                                onChange={(e) => setFormData({ ...formData, public: e.target.value === 'true' })}
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Public" />
                                                <FormControlLabel value="false" control={<Radio />} label="Private" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Paper>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
                                        {loading ? 'Submitting...' : 'Submit'}
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            )}
            <RegisterModal
                open={isRegisterModalOpen}
                handleClose={() => setIsRegisterModalOpen(false)}
            />
        </>
    );
};

export default PropertySignUp;
