import React from 'react';
import { TextField, Grid } from '@mui/material';

const TextFieldComponent = ({ label, name, value, onChange, required = false, type = "text", rows = 1, multiline = false, variant = "filled" }) => (
    <Grid item xs={12} sm={rows > 1 ? 12 : 6}>
        <TextField
            fullWidth
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            required={required}
            type={type}
            multiline={multiline}
            rows={rows}
            variant={variant}
            sx={{ mt: 3 }} // Adding margin top for spacing
            onWheel={type === "number" ? (e) => e.target.blur() : null} // Prevent scrolling from changing number
        />
    </Grid>
);

export default TextFieldComponent;
