import { create } from 'zustand';
import { auth, functions, db } from '../firebase'; // Ensure these imports are correct
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { signInWithEmailAndPassword, signOut, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';

const handleCreateAccount = async (email, password, phoneNumber, firstName, lastName, termsAgreed) => {
  const createAccount = httpsCallable(functions, 'auth-createAccount');
  const error = await createAccount({ email, password, phoneNumber, firstName, lastName, termsAgreed });
  return error.data;
}

const useUserSessionStore = create((set) => ({
  user: null,
  setUser: (user) => set({ user }),
  userClaims: { "subscribedYearly": false },
  setUserClaims: (userClaims) => set({ userClaims }),
  userDoc: null,
  setUserDoc: async (uid) => {
    const userRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      set({ userDoc: userDoc.data() });
    }
  },
  register: async (email, password, phoneNumber, firstName, lastName, termsAgreed) => {
    try {
      const error = await handleCreateAccount(email, password, phoneNumber, firstName, lastName, termsAgreed);
      if (error.error) {
        alert(error.error);
        return { loading: false };
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (userCredential.user) {
          // Send email verification
          sendEmailVerification(auth.currentUser);
          alert('Verification email sent, \nplease verify your email');
        }
      }
      return { loading: false };
    } catch (e) {
      alert(e.message);
    }
  },
  login: async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      if (e.code === 'auth/user-not-found') {
        alert('User not found. Please sign up first.');
      } else if (e.code === 'auth/invalid-email') {
        alert('Invalid email.');
      } else if (e.code === 'auth/wrong-password') {
        alert('Password is incorrect.');
      } else if (e.code === 'auth/internal-error') {
        alert('No password provided.');
      } else if (e.code === 'auth/missing-email') {
        alert('Please provide an email. Check that there is no space at the end of the email.');
      } else if (e.code === 'auth/network-request-failed') {
        alert('Please check your internet connection.');
      } else {
        alert(e.message);
      }
    }
  },
  logout: async () => {
    try {
      await signOut(auth);
      set({ user: null, userClaims: {}, userDoc: null });
    } catch (e) {
      console.log(e);
    }
  },
}));

onAuthStateChanged(auth, (user) => {
  useUserSessionStore.getState().setUser(user);
});

export default useUserSessionStore;
