import React from 'react';
import './TestimonialsSection.css';

const testimonials = [
  {
    text: "Joining Your Happy Place has been a game-changer for our rental business. The exposure and bookings have exceeded our expectations.",
  },
  {
    text: "The support team is fantastic. They are always available to help and provide valuable insights.",
  },
];

const TestimonialsSection = () => {
  return (
    <div className="testimonials-section">
      <h2 className="testimonials-header">What Our Partners Say</h2>
      <div className="testimonial-container">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-box">
            <p>{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsSection;
