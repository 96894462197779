// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#cda93e',
      contrastText: '#000000',
    },
    secondary: {
      main: '#e8c456',
    },
  },
  typography: {
    button: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#e8c456',
          '&:hover': {
            backgroundColor: '#e5b31e',
          },
          color: '#000000',
          fontSize: '1rem',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            color: '#FFFFFF', // Text color
            backgroundColor: '#494949', // Input background color
          },
          '& .MuiInputLabel-root': {
            color: '#B0B0B0', // Label color
          },

          '& .MuiFormHelperText-root': {
            color: '#B0B0B0', // Helper text color
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#B0B0B0', // Placeholder text color
            opacity: 1, // Ensure the opacity is applied
          },
        },
      },
    },

    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: '#333', // Change this to your desired background color
          color: '#fff', // Change this to your desired text color
          padding: '20px',
          borderRadius: '8px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#4f4f4f', // Change this to your desired background color
          color: '#fff', // Change this to your desired text color
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#B0B0B0', // Change this to your desired text color
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'white', // Change this to your desired color
        },
      },
    },
  },
});

export default theme;
