import React, { useState, useCallback, useEffect } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from 'react-photo-gallery';
import { Button, Box } from '@mui/material';
import './PhotoGallery.css';

const PhotoGallery = ({ photos = {} }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const categories = {
    interiorPhotos: 'Interior Photos',
    exteriorPhotos: 'Exterior Photos',
    kitchenPhotos: 'Kitchen Photos',
    locationPhotos: 'Location Photos',
    bedrooms: 'Bedroom Photos'
  };

  const extractPhotos = (photosObj) => {
    return Object.entries(photosObj || {}).map(([key, value]) => ({
      src: value.url,
      width: 4,
      height: 3,
      description: value.description || ''
    }));
  };

  const extractBedroomPhotos = (bedroomsObj) => {
    return Object.entries(bedroomsObj || {}).flatMap(([bedroomKey, bedroomPhotos]) => 
      Object.entries(bedroomPhotos).map(([photoKey, value]) => ({
        src: value.url,
        width: 4,
        height: 3,
        description: value.description || ''
      }))
    );
  };

  const categoryPhotos = {
    interiorPhotos: extractPhotos(photos?.interiorPhotos),
    exteriorPhotos: extractPhotos(photos?.exteriorPhotos),
    kitchenPhotos: extractPhotos(photos?.kitchenPhotos),
    locationPhotos: extractPhotos(photos?.locationPhotos),
    bedrooms: extractBedroomPhotos(photos?.bedrooms)
  };

  const allPhotos = Object.keys(categoryPhotos).flatMap(category => categoryPhotos[category]).filter(photo => photo.src);

  const openLightbox = useCallback((index, category) => {
    setCurrentImage(index);
    setCurrentCategory(category);
    setViewerIsOpen(true);
    document.body.style.overflow = 'hidden'; // Disable scroll when lightbox is open
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
    document.body.style.overflow = 'visible'; // Re-enable scroll when lightbox is closed
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.overflow = 'visible'; // Ensure scroll is enabled on unmount
    };
  }, []);

  return (
    <div className="photo-gallery">
      {Object.keys(categoryPhotos).map((category) => (
        categoryPhotos[category].length > 0 &&
        (
          <div key={category}>
            <h2>{categories[category]}</h2>
            
            <Gallery
              photos={categoryPhotos[category].slice(0, isMobile ? 2 : 5)}
              onClick={(event, obj) => openLightbox(obj.index, category)}
            />
            {categoryPhotos[category].length > (isMobile ? 2 : 5) && (
              <Box display="flex" justifyContent="center" mt={2}>
                <Button variant="contained" onClick={() => openLightbox(0, category)}>
                  View More
                </Button>
              </Box>
            )}
          </div>
        )
      ))}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={categoryPhotos[currentCategory].map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.description
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      {allPhotos.map((photo, index) => (
        <meta key={index} name="description" content={photo.description} />
      ))}
    </div>
  );
};

export default PhotoGallery;
