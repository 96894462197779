import Button from '@mui/material/Button';
import React, { useState } from 'react';
import './PropertyRulesScroller.css'; // Ensure the path is correct

const PropertyRulesScroller = ({ rules, startIndex = 0, count = 2, showToggleAll = true }) => {
  const [showAll, setShowAll] = useState(false);

  const displayedRules = showAll ? rules : rules.slice(startIndex, startIndex + count);

  return (
    <div className="property-rules-scroller">
      <h2>Property Rules</h2>
      {displayedRules.map((rule, index) => (
        <div key={index} className="rule">
          <h3>{rule.header}</h3>
          <p>{rule.description}</p>
        </div>
      ))}
      {showToggleAll && (
        <Button variant="outlined" color="inherit" onClick={() => setShowAll(!showAll)} className="show-all-rules">
          {showAll ? 'See Less' : 'See All Rules'}
        </Button>
      )}
    </div>
  );
};

export default PropertyRulesScroller;
