// HeroSection.js
import React from 'react';
import './HeroSection.css';  // Ensure you create this CSS file

function HeroSection() {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h5>IT'S A VACATION HOME, BUT BETTER</h5>
        <h1>The NPHomes difference</h1>
        <p>NPHomes is different because we operate 100% of the homes on our platform – combining the quality of a luxury hotel with the comfort of a private vacation home.</p>
      </div>
    </div>
  );
}

export default HeroSection;
