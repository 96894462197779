import React, { useState, useEffect } from 'react';
import { db } from '../../../../../firebase'; // Make sure this path is correct
import { collection, getDocs } from 'firebase/firestore';
import Button from '@mui/material/Button';
import './Amenities.css';

const Amenities = ({ name, description, items }) => {
    const [showAll, setShowAll] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [amenitiesMap, setAmenitiesMap] = useState({});

    const toggleAmenities = () => {
        setShowAll(!showAll);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchAmenities = async () => {
            const amenitiesCollection = collection(db, 'amenities');
            const amenitiesSnapshot = await getDocs(amenitiesCollection);
            const amenitiesData = amenitiesSnapshot.docs.reduce((acc, doc) => {
                acc[doc.id] = {
                    name: doc.data().name,
                    description: doc.data().description,
                };
                return acc;
            }, {});
            setAmenitiesMap(amenitiesData);
        };

        fetchAmenities();
    }, []);

    const initialItemsCount = isMobile ? 4 : 5;

    return (
        <div className="amenities-container">
            <div className="amenities-content">
                <div className="amenities-header">
                    <h2>{name}</h2>
                    <p>{description}</p>
                </div>
                <div className="amenities-list">
                    {items.slice(0, showAll ? items.length : initialItemsCount).map((item, index) => (
                        <div key={index} className="amenity-item">
                            <span>{amenitiesMap[item.label]?.name || item.label}</span>
                            {amenitiesMap[item.label]?.description && (
                                <p className="amenity-description">{amenitiesMap[item.label].description}</p>
                            )}
                        </div>
                    ))}
                </div>

                {items.length > initialItemsCount && (
                    <Button variant="outlined" color="inherit" onClick={toggleAmenities}>
                        {showAll ? 'Show Less' : 'Show All Amenities'}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Amenities;
