import React from 'react';
import './Filters.css'; // Ensure this CSS file exists and is correctly linked

function Filters({ setFilter, currentFilter, amenities }) {
  const isActive = (filterName) => currentFilter === filterName ? 'button-active' : '';

  return (
    <div className="filters">
      <button className={`button ${isActive('')}`} onClick={() => setFilter('')}>All locations</button>
      {amenities.map(amenity => (
        <button
          key={amenity.id}
          className={`button ${isActive(amenity.id)}`}
          onClick={() => setFilter(amenity.id)}
        >
          {amenity.name}
        </button>
      ))}
    </div>
  );
}

export default Filters;
