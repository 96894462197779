// TermsModal.js
import React from 'react';
import './TermsModal.css';

function TermsModal({ isOpen, onClose, property }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Booking Rules & Agreements</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <h3>General Booking Rules</h3>
          <p>1. The booking is confirmed only upon receipt of the full payment or deposit as specified.</p>
          <p>2. All bookings are subject to availability and confirmation.</p>
          <p>3. Guests must be at least 18 years old to make a booking.</p>
          <p>4. The booking is non-transferable and non-refundable unless specified otherwise.</p>

          <h3>Check-in and Check-out</h3>
          <p>1. Check-in time is from {property.rules?.checkInTimeEarliest || '2:00 PM'}.</p>
          <p>2. Check-out time is by {property.rules?.checkOutTime || '10:00 AM'}.</p>
          <p>3. Early check-in or late check-out requests are subject to availability and may incur additional charges.</p>
          <p>4. Check-in runs until {property.rules?.checkInTimeLatest}. Check-in’s after {property.rules?.checkInTimeLatest} can be arranged directly with the property in advance.</p>

          <h3>Check-in Procedure</h3>
          <p>{property.rules?.checkInProcedure || 'Self Check in with Lockbox'}</p>

          <h3>Payment and Cancellation</h3>
          <p>1. Full payment or a deposit is required to confirm the booking, as specified at the time of booking.</p>
          <p>2. Cancellations made within 14 days of the check-in date will forfeit the full payment or deposit.</p>
          <p>3. Cancellations made more than 14 days prior to the check-in date will receive a full refund minus a 10% administrative fee.</p>

          <h3>Guest Responsibilities</h3>
          <p>1. Guests are responsible for keeping the property in good condition and returning it in the same state as found upon arrival.</p>
          <p>2. Any damages or losses incurred during the stay will be charged to the guest's account.</p>
          <p>3. Guests must adhere to all house rules and regulations provided upon arrival.</p>
          <p>4. This home follows a strict maximum occupancy rule. Please make sure that the total number of guests accompanying you (including both children and adults) doesn’t exceed the indicated maximum occupancy above to avoid cancellation of your stay.</p>

          <h3>House Rules</h3>
          <p>1. {property.rules?.smokingPolicy ? 'Smoking allowed' : 'No smoking'}.</p>
          <p>2. {property.rules?.petsAllowed ? 'Pets allowed' : 'No pets allowed'}.</p>
          <p>3. {property.rules?.allowsParties ? 'Parties or events are allowed.' : 'No parties or events'}.</p>
          <p>4. {property.rules?.allowsLateNoise ? 'We allow loud noise after 10pm.' : 'No Loud Noise after hours - Please kindly be mindful to our home and our wonderful neighbors.'}.</p>
          
          <h3>Breakages</h3>
          <p>Please report any breakages to the Property directly.</p>

          <h3>Additional Costs</h3>
          <p>{property.rules?.additionalCosts || 'N/A'}</p>

          <h3>Liability</h3>
          <p>1. The property owner is not liable for any accidents, injuries, or illnesses that occur on the premises or its facilities.</p>
          <p>2. The property owner is not responsible for the loss of personal belongings or valuables of the guest.</p>

          <h3>Privacy</h3>
          <p>1. Guest information is collected for the purpose of processing bookings and will be kept confidential.</p>
          <p>2. Personal data will not be shared with third parties without the guest's consent, except as required by law.</p>

          <h3>Amendments</h3>
          <p>1. The property owner reserves the right to amend these terms and conditions at any time. Guests will be notified of any changes prior to their stay.</p>

          <h3>Governing Law</h3>
          <p>1. These terms and conditions are governed by the laws of South Africa. Any disputes arising from these terms and conditions shall be resolved in the courts of South Africa.</p>
        </div>
      </div>
    </div>
  );
}

export default TermsModal;
