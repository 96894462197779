import React, { useEffect } from 'react';

import Navbar from './components/Navbar/Navbar';
import HomePage from './components/FrontEnd/HomePage/HomePage';

import Footer from './components/Footer/Footer'; // Adjust the path based on your project structure


function App() {

  
  
  return (

    <div>
      <Navbar />
      <HomePage />
      <Footer />
    </div>

  );
}

export default App;
