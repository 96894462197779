import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PropertyCalendarDesktop from '../../PropertyPage/Calendar/PropertyCalendarDesktop/PropertyCalendarDesktop';
import './TripDetails.css';

function TripDetails({ checkInDate, checkOutDate, setGuestCount, guestCount, lodgeSlug }) {

  const [showCalendar, setShowCalendar] = useState(false);
  const [editingGuests, setEditingGuests] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleEditGuests = () => {
    setEditingGuests(true);
  };

  const handleSaveGuests = () => {
    setGuestCount(guestCount);
    setEditingGuests(false);
  };

  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  return (
    <div className="trip-details">
      <div className="trip-detail-item">
        <h3>Date</h3>
        <p>{`${formatDate(checkInDate)} to ${formatDate(checkOutDate)}, check-in at 4:00pm`}</p>
        {showCalendar ?
          <Button onClick={toggleCalendar} variant="outlined">Close</Button> : <Button onClick={toggleCalendar} variant="outlined">Edit dates</Button>}
      </div>

      <div className={`calendar-container ${showCalendar ? 'show' : ''}`}>
        <PropertyCalendarDesktop lodgeSlug={lodgeSlug} setShowCalendar={setShowCalendar} />
      </div>

      <div className="trip-detail-item">
        <h3>Guests</h3>
        {editingGuests ? (
          <div>
            <TextField
              type="number"
              value={guestCount}
              onChange={(e) => setGuestCount(e.target.value)}
              variant="outlined"
              inputProps={{ min: "1", max: "100" }}
              sx={{
                marginRight: 2,
                '& .MuiInputBase-input': {
                  color: 'white',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .Mui-focused': {
                  color: 'white',
                },
              }}
            />
            <Button onClick={handleSaveGuests} variant="contained">Save</Button>
          </div>
        ) : (
          <div>
            <p>{guestCount} {guestCount > 1 ? "Adults" : "Adult"} </p>
            <Button onClick={handleEditGuests} variant="outlined">Edit guests</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TripDetails;
