import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './PropertyCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBath, faUsers, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

function PropertyCard({ name, photos, address, availableFrom, availableTo, slug, bedroomCount, bathroomCount, maxGuestCount }) {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        swipe: true,
    };

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <button className="arrow next" onClick={onClick}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <button className="arrow prev" onClick={onClick}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        );
    }

    const extractPhotos = (photosObj) => {
        return Object.entries(photosObj || {}).map(([key, value]) => ({
            url: value.url,
            description: value.description || ''
        }));
    };

    const images = [
        { url: photos?.coverPhoto?.url, description: photos?.coverPhoto?.description || '' },
        ...extractPhotos(photos?.interiorPhotos),
        ...extractPhotos(photos?.exteriorPhotos),
        ...extractPhotos(photos?.kitchenPhotos),
        ...extractPhotos(photos?.locationPhotos),
        ...Object.values(photos?.bedrooms || {}).flat().map(photo => ({
            url: photo.url,
            description: photo.description || ''
        }))
    ].filter(photo => photo.url);

    return (
        <div className="property-card">
            <Link to={`/property/${slug}`} className="property-card">
                <Slider {...settings}>
                    {images.map((img, index) => (
                        <div key={index}>
                            <img src={img.url} alt={img.description} />
                            <meta name="description" content={img.description} />
                        </div>
                    ))}
                </Slider>

                <div className="property-card-info">
                    <div className="property-location">{`${address?.suburb}, ${address?.city}`}</div>
                    <div className="property-title">{name || 'Unnamed Property'}</div>
                    {/* <div className="property-availability">
                        <span className="availability-label">Next available: </span>
                        <span className="availability-date">{availableFrom || 'N/A'} to {availableTo || 'N/A'}</span>
                    </div> */}
                    <div className="property-icons">
                        <span className="property-icon"><FontAwesomeIcon icon={faBed} /> {bedroomCount || 0}</span>
                        <span className="property-icon"><FontAwesomeIcon icon={faBath} /> {bathroomCount || 0}</span>
                        <span className="property-icon"><FontAwesomeIcon icon={faUsers} /> {maxGuestCount || 0}</span>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default PropertyCard;
