// src/components/PropertyPhotosUpload/PropertyPhotosUpload.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, storage } from '../../../firebase';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Container, Typography, Button, TextField, Box, Grid, CircularProgress } from '@mui/material';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';

const PropertyPhotosUpload = () => {
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const [property, setProperty] = useState(null);
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [interiorPhotos, setInteriorPhotos] = useState([]);
    const [exteriorPhotos, setExteriorPhotos] = useState([]);
    const [kitchenPhotos, setKitchenPhotos] = useState([]);
    const [locationPhotos, setLocationPhotos] = useState([]);
    const [bedroomPhotos, setBedroomPhotos] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploaded, setUploaded] = useState(false);

    useDocumentTitleAndDescription(`Upload Property Photos: ${property?.name}`, 'Upload photos for a property');

    useEffect(() => {
        const fetchProperty = async () => {
            setLoading(true);
            try {
                const propertiesCollection = collection(db, 'properties');
                const q = query(propertiesCollection, where('slug', '==', slug));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const propertyDoc = querySnapshot.docs[0];
                    setProperty({ id: propertyDoc.id, ...propertyDoc.data() });
                } else {
                    console.error('No such document!');
                }
            } catch (error) {
                console.error('Error fetching property:', error);
            }
            setLoading(false);
        };

        fetchProperty();
    }, [slug]);

    const handleFileChange = (e, setPhotos) => {
        const files = Array.from(e.target.files);
        setPhotos(files);
    };

    const handleSingleFileChange = (e) => {
        setCoverPhoto(e.target.files[0]);
    };

    const handleBedroomFileChange = (e, bedroomIndex) => {
        const files = Array.from(e.target.files);
        setBedroomPhotos((prev) => ({ ...prev, [bedroomIndex]: files }));
    };

    const uploadFile = async (file, path, setProgress) => {
        return new Promise((resolve, reject) => {
            const fileRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(percent);
                },
                (err) => reject(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => resolve(url));
                }
            );
        });
    };

    const handleUpload = async () => {
        setLoading(true);
        try {
            const propertyDocRef = doc(db, 'properties', property.id);
            const propertySnap = await getDoc(propertyDocRef);

            if (propertySnap.exists()) {
                const propertyData = propertySnap.data();

                const updatedPhotos = {
                    coverPhoto: propertyData.photos?.coverPhoto || { url: null, description: '' },
                    interiorPhotos: propertyData.photos?.interiorPhotos || {},
                    exteriorPhotos: propertyData.photos?.exteriorPhotos || {},
                    kitchenPhotos: propertyData.photos?.kitchenPhotos || {},
                    locationPhotos: propertyData.photos?.locationPhotos || {},
                    bedrooms: propertyData.photos?.bedrooms || {}
                };

                const generateDescription = (category, index) => {
                    return `${category.replace(/Photos$/, '')} photo ${index + 1} of ${property.name} villa in ${property.city}, ${property.country}`;
                };

                if (coverPhoto) {
                    updatedPhotos.coverPhoto.url = await uploadFile(coverPhoto, `properties/${slug}/coverPhoto.jpg`, setUploadProgress);
                    updatedPhotos.coverPhoto.description = generateDescription('Cover', 0);
                }

                if (interiorPhotos.length) {
                    const interiorPhotosURLs = await Promise.all(interiorPhotos.map((file, index) =>
                        uploadFile(file, `properties/${slug}/interior/photo_${index}.jpg`, setUploadProgress)
                    ));
                    interiorPhotosURLs.forEach((url, index) => {
                        updatedPhotos.interiorPhotos[`photo_${index}`] = { url, description: generateDescription('Interior', index) };
                    });
                }

                if (exteriorPhotos.length) {
                    const exteriorPhotosURLs = await Promise.all(exteriorPhotos.map((file, index) =>
                        uploadFile(file, `properties/${slug}/exterior/photo_${index}.jpg`, setUploadProgress)
                    ));
                    exteriorPhotosURLs.forEach((url, index) => {
                        updatedPhotos.exteriorPhotos[`photo_${index}`] = { url, description: generateDescription('Exterior', index) };
                    });
                }

                if (kitchenPhotos.length) {
                    const kitchenPhotosURLs = await Promise.all(kitchenPhotos.map((file, index) =>
                        uploadFile(file, `properties/${slug}/kitchen/photo_${index}.jpg`, setUploadProgress)
                    ));
                    kitchenPhotosURLs.forEach((url, index) => {
                        updatedPhotos.kitchenPhotos[`photo_${index}`] = { url, description: generateDescription('Kitchen', index) };
                    });
                }

                if (locationPhotos.length) {
                    const locationPhotosURLs = await Promise.all(locationPhotos.map((file, index) =>
                        uploadFile(file, `properties/${slug}/location/photo_${index}.jpg`, setUploadProgress)
                    ));
                    locationPhotosURLs.forEach((url, index) => {
                        updatedPhotos.locationPhotos[`photo_${index}`] = { url, description: generateDescription('Location', index) };
                    });
                }

                for (const [bedroomIndex, files] of Object.entries(bedroomPhotos)) {
                    if (files.length) {
                        const urls = await Promise.all(files.map((file, index) =>
                            uploadFile(file, `properties/${slug}/bedrooms/bedroom${bedroomIndex}/photo_${index}.jpg`, setUploadProgress)
                        ));
                        urls.forEach((url, index) => {
                            if (!updatedPhotos.bedrooms[`bedroom${bedroomIndex}`]) {
                                updatedPhotos.bedrooms[`bedroom${bedroomIndex}`] = {};
                            }
                            updatedPhotos.bedrooms[`bedroom${bedroomIndex}`][`photo_${index}`] = { url, description: generateDescription(`Bedroom ${bedroomIndex}`, index) };
                        });
                    }
                }

                await updateDoc(propertyDocRef, {
                    photos: updatedPhotos,
                    updatedAt: new Date()
                });

                setUploaded(true);
            } else {
                console.error('No such document!');
            }
        } catch (error) {
            console.error('Error uploading photos:', error);
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Upload {property?.name} Photos</Typography>
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Cover Photo</Typography>
                        <TextField type="file" onChange={handleSingleFileChange} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Interior Photos</Typography>
                        <TextField type="file" inputProps={{ multiple: true }} onChange={(e) => handleFileChange(e, setInteriorPhotos)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Exterior Photos</Typography>
                        <TextField type="file" inputProps={{ multiple: true }} onChange={(e) => handleFileChange(e, setExteriorPhotos)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Kitchen Photos</Typography>
                        <TextField type="file" inputProps={{ multiple: true }} onChange={(e) => handleFileChange(e, setKitchenPhotos)} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Location Photos</Typography>
                        <TextField type="file" inputProps={{ multiple: true }} onChange={(e) => handleFileChange(e, setLocationPhotos)} fullWidth />
                    </Grid>
                    {property?.bedrooms && Object.keys(property.bedrooms).map((key, index) => (
                        <Grid item xs={12} key={index}>
                            <Typography variant="h6">Bedroom {index + 1} Photos</Typography>
                            <TextField type="file" inputProps={{ multiple: true }} onChange={(e) => handleBedroomFileChange(e, key)} fullWidth />
                        </Grid>
                    ))}
                </Grid>
                <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={handleUpload} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Upload Photos'}
                    </Button>
                </Box>
                {uploaded && <Typography variant="body1" color="success.main">Photos uploaded successfully!</Typography>}
                <Typography variant="body1">Upload Progress: {uploadProgress}%</Typography>
            </Box>
        </Container>
    );
};

export default PropertyPhotosUpload;
