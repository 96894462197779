// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// MAIN
const firebaseConfig = {
  apiKey: "AIzaSyBaTwnHlfr9gF0NalvhXbHFTU9cUanXhG4",
  authDomain: "yourhappyplace-5421a.firebaseapp.com",
  projectId: "yourhappyplace-5421a",
  storageBucket: "yourhappyplace-5421a.appspot.com",
  messagingSenderId: "857568434561",
  appId: "1:857568434561:web:ba6a16a6c3a41e1d8e7080",
  measurementId: "G-W0RFZJGYHR"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export default app



