import React, { useEffect } from 'react';

import FeaturesGrid from './Features/FeaturesGrid';
import HeroSection from './Features/HeroSection';
import PropertyGrid from './PropertyGrid/PropertyGrid';
import SearchBar from './SearchBar/SearchBar';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';


function HomePage() {

  useDocumentTitleAndDescription("Book Your Happy Place", "Find the perfect Villa for your next trip")


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div>
      <SearchBar />
      <PropertyGrid />
      <HeroSection />
      <FeaturesGrid />
    </div>
  );
}

export default HomePage;
