import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RegisterModal from '../AuthModal/RegisterModal';
import LoginModal from '../AuthModal/LoginModal';
import useUserSessionStore from '../../stores/userSessionStore';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../assets/Logo.jpeg';
import './Navbar.css';

const pages = {
  'Sign up your villa': '/property-sign-up',
  'Book now': '/properties',
};

const settings = [
  {
    title: 'Manage Properties',
    url: '/property-selection',
    mustBeAdminOrOwner: true,
  },
  {
    title: 'Manage Enquiries',
    url: '/manage-enquiries',
    mustBeAdminOrOwner: true,
  },
  {
    title: 'Logout',
    action: 'logout',
  },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const user = useUserSessionStore((state) => state.user);
  const userDoc = useUserSessionStore((state) => state.userDoc);
  const setUserDoc = useUserSessionStore((state) => state.setUserDoc);
  const logout = useUserSessionStore((state) => state.logout);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (action) => {
    setAnchorElUser(null);
    if (action === 'logout') {
      logout();
      navigate('/');
    }
  };

  const handleOpenRegisterModal = () => setOpenRegisterModal(true);
  const handleCloseRegisterModal = () => setOpenRegisterModal(false);

  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  // Fetch user document after login
  useEffect(() => {
    if (user) {
      setUserDoc(user.uid);
    }
  }, [user, setUserDoc]);

  const renderSettingsMenuItems = () => {
    return settings.map(({ title, url, mustBeAdminOrOwner, action }) => {
      if (mustBeAdminOrOwner && (!userDoc || (userDoc.userRole !== 'admin' && !userDoc.isOwner))) {
        return null;
      }
      return (
        <MenuItem
          key={title}
          onClick={() => handleCloseUserMenu(action || url)}
        >
          <Typography textAlign="center">
            {action ? (
              title
            ) : (
              <Link
                to={url}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {title}
              </Link>
            )}
          </Typography>
        </MenuItem>
      );
    });
  };

  return (
    <>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
              <Link to="/">
                <img src={logo} alt="Logo" className="logo-image" />
              </Link>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Your Happy Place
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {[
                  ...Object.entries(pages).map(([page, path]) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">
                        <Link
                          to={path}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {page}
                        </Link>
                      </Typography>
                    </MenuItem>
                  )),
                  !user && (
                    <MenuItem key="register" onClick={handleOpenRegisterModal}>
                      <Typography textAlign="center" style={{ color: 'inherit' }}>
                        Register
                      </Typography>
                    </MenuItem>
                  ),
                  !user && (
                    <MenuItem key="login" onClick={handleOpenLoginModal}>
                      <Typography textAlign="center" style={{ color: 'inherit' }}>
                        Login
                      </Typography>
                    </MenuItem>
                  ),
                ]}
              </Menu>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <Link to="/">
                <img src={logo} alt="Logo" className="logo-image" />
              </Link>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              YHP
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {Object.entries(pages).map(([page, path]) => (
                <Link to={path} key={page} style={{ textDecoration: 'none' }}>
                  <Button sx={{ my: 2, display: 'block', mr: 2 }}>
                    {page}
                  </Button>
                </Link>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {user ? (
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Button sx={{ mr: 2 }} onClick={handleOpenRegisterModal}>
                    Register
                  </Button>
                  <Button sx={{ mr: 2 }} onClick={handleOpenLoginModal}>
                    Login
                  </Button>
                </>
              )}
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {renderSettingsMenuItems()}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <RegisterModal open={openRegisterModal} handleClose={handleCloseRegisterModal} />
      <LoginModal open={openLoginModal} handleClose={handleCloseLoginModal} openRegisterModal={handleOpenRegisterModal} />
    </>
  );
}

export default ResponsiveAppBar;
