import React from 'react';
import './FeaturesGrid.css';  // Make sure to create this CSS file

const features = [
  {
    title: "100% NPHomes operated",
    description: "We’ll never pass you off to a stranger. All of the locations on our platform are operated by us and managed to the highest possible standards to create an experience guests love.",
    backgroundImage: "path_to_background_image_1.jpg"
  },
  {
    title: "Hand-picked quality",
    description: "We’ll never not look like the pictures. We select homes in the most beautiful destinations and pour our hearts into making them magical so you can leave your stress at the door.",
    backgroundImage: "path_to_background_image_2.jpg"
  },
  {
    title: "Hotel-grade amenities",
    description: "We’ll never leave you with nothing to do. From workstations with super fast WiFi to fitness centers, pools, and saunas, all of our locations are set up for you to effortlessly blend work and play.",
    backgroundImage: "path_to_background_image_3.jpg"
  },
  {
    title: "100% NPHomes operated",
    description: "We’ll never pass you off to a stranger. All of the locations on our platform are operated by us and managed to the highest possible standards to create an experience guests love.",
    backgroundImage: "path_to_background_image_1.jpg"
  },
  {
    title: "Hand-picked quality",
    description: "We’ll never not look like the pictures. We select homes in the most beautiful destinations and pour our hearts into making them magical so you can leave your stress at the door.",
    backgroundImage: "path_to_background_image_2.jpg"
  },
  {
    title: "Hotel-grade amenities",
    description: "We’ll never leave you with nothing to do. From workstations with super fast WiFi to fitness centers, pools, and saunas, all of our locations are set up for you to effortlessly blend work and play.",
    backgroundImage: "path_to_background_image_3.jpg"
  }
];

function FeaturesGrid() {
  return (
    <div className="features-grid">
      {features.map((feature, index) => (
        <div key={index} className="feature-item">
          <h2>{feature.title}</h2>
          <p>{feature.description}</p>
        </div>
      ))}
    </div>
  );
}

export default FeaturesGrid;
