import React from 'react';
import { useNavigate } from 'react-router-dom';
import LandingPageHeroSection from './HeroSection/LandingPageHeroSection';
import WhyPartnerSection from './WhyPartnerSection/WhyPartnerSection';
import ShowcaseSection from './ShowcaseSection/ShowcaseSection';
import TestimonialsSection from './TestimonialsSection/TestimonialsSection';
import FAQSection from './FAQSection/FAQSection';
import StepSection from './StepSection/StepSection';
import Ticker from './Ticker/Ticker';
import './LandingPage.css';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';

const LandingPage = () => {

  useDocumentTitleAndDescription('Landing Page', "We believe that a true home is more than just a place—it's a feeling of comfort, luxury, and belonging. That's why we handpick only the finest luxury villas across South Africa, ensuring that every stay is a unique and unforgettable experience.")

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/property-sign-up');
  };

  const showcases = [
    {
      header: 'Marula Sunrise',
      image: 'https://firebasestorage.googleapis.com/v0/b/yourhappyplace-5421a.appspot.com/o/landingPage%2FMarulaSunrise.png?alt=media&token=41c2075a-3ddf-4e86-8a43-356b366c9005',
      buttonText: 'Add Your Villa',
      onButtonClick: () => navigate('/property-sign-up'),
    },
    {
      header: 'Fifty Five',
      image: 'https://firebasestorage.googleapis.com/v0/b/yourhappyplace-5421a.appspot.com/o/landingPage%2FFiftyFive.png?alt=media&token=fa395477-95ed-4e23-a257-331bc4b6d24c',
      buttonText: 'Sign Up Today',
      onButtonClick: () => navigate('/property-sign-up'),
    },
    {
      header: 'Serenity Plains',
      image: 'https://firebasestorage.googleapis.com/v0/b/yourhappyplace-5421a.appspot.com/o/landingPage%2FSerenityPlains.png?alt=media&token=3bf2e123-437f-459e-aa49-931fa79ceb98',
      buttonText: 'Start Renting',
      onButtonClick: () => navigate('/property-sign-up'),
    },
  ];

  return (
      <div className='landing-page'>
        <Ticker message="Launching Spring of 2024 - 102 villas signed up" />
        <div className="page-content">
          <LandingPageHeroSection
            header="Home is where the heart is"
            subheader="Your Happy Place"
            description="We believe that a true home is more than just a place—it's a feeling of comfort, luxury, and belonging. That's why we handpick only the finest luxury villas across South Africa, ensuring that every stay is a unique and unforgettable experience."
            buttonText="List Your Villa Today"
            backgroundImage={'https://firebasestorage.googleapis.com/v0/b/yourhappyplace-5421a.appspot.com/o/landingPage%2FheartHands.png?alt=media&token=ec56c787-00eb-4549-ab5b-3faee7047485'}
            onButtonClick={handleButtonClick}
            height="60vh"
          />
          <WhyPartnerSection />
          <ShowcaseSection showcases={showcases} />
          <StepSection />
          <FAQSection />
          <TestimonialsSection />
        </div>
      </div>
  );
}

export default LandingPage;
