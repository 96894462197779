import React from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import './ShowcaseSection.css';

const ShowcaseSection = ({ showcases }) => {
  return (
    <Box className="showcase-section">
      <Typography variant="h4" align="center" gutterBottom
        sx={{ fontWeight: 'bold', mt: 2, mb: 2 }}
      >
        Join Our Showcase of Elegance
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {showcases.map((showcase, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} className="showcase-item">
            <Typography variant="h3" align="center"
              sx={{ fontWeight: 'bold', mt: 2, mb: 2,
                fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.6rem' }
               }}
            >
              {showcase.header}
            </Typography>
            <img src={showcase.image} alt={showcase.header} className="showcase-image" />
            <Button
              variant="contained"
              color="primary"
              className="showcase-button"
              onClick={showcase.onButtonClick}
              size="small"
              sx={{
                width: '100%',
                // Make text always on one line
                whiteSpace: 'nowrap',
              }}
              
            >
              {showcase.buttonText}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ShowcaseSection;
