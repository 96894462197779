import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Button from '@mui/material/Button';
import './PropertyCalendarDesktop.css';

function PropertyCalendarDesktop({ lodgeSlug, setShowCalendar }) {
  const initialState = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  const [state, setState] = useState([initialState]);
  const navigate = useNavigate();

  const handleSelect = (ranges) => {
    setState([ranges.selection]);
  };

  const handleClearDates = () => {
    setState([{ ...initialState }]);
  };

  const handleReserve = () => {
    const { startDate, endDate } = state[0];
    const formattedStartDate = addDays(startDate, 1).toISOString().split('T')[0];
    const formattedEndDate = addDays(endDate, 1).toISOString().split('T')[0]; // Add one day to the end date
    navigate(`/trip-checkout/${lodgeSlug}?checkIn=${formattedStartDate}&checkOut=${formattedEndDate}`);
    try {
      setShowCalendar(false); // Close the calendar}
    }
    catch (error) {
      console.error(error);
    }
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="calendar-container-desktop">
      <h2>Select check-in date</h2>
      <br />
      <h4 style={{ fontWeight: 'normal', color: 'grey' }}>Select dates to see pricing</h4>
      <br />
      <DateRange
        onChange={handleSelect}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={state}
        direction="vertical"
        minDate={new Date()}
        maxDate={addDays(new Date(), 365)}
        rangeColors={["#000000"]}
      />
      <div className="calendar-actions">
        <Button variant="contained" sx={{ backgroundColor: '#333', color: '#fff', '&:hover': { backgroundColor: '#555' } }} onClick={handleClearDates}>
          Clear Dates
        </Button>
        <Button variant="outlined" onClick={handleReserve}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default PropertyCalendarDesktop;
