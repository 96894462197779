import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Contact from "./components/FrontEnd/ContactUs/Contact";
import PropertyPage from "./components/FrontEnd/PropertyPage/PropertyPage";
import Navbar from "./components/Navbar/Navbar";
import TripCheckoutPage from "./components/FrontEnd/TripCheckoutPage/TripCheckoutPage";
import PropertySignUp from "./components/AdminPanel/PropertySignUp/PropertySignUp";
import SuccessPage from './components/AdminPanel/PropertySignUp/SuccessPage/SuccessPage';
import LandingPage from "./components/FrontEnd/LandingPage/LandingPage";

import PropertySelectPage from "./components/AdminPanel/PropertySelectPage/PropertySelectPage";
import PropertyPhotosUpload from "./components/AdminPanel/PropertyPhotosUpload/PropertyPhotosUpload";
import AddPhotoMetaData from "./components/AdminPanel/AddPhotoMetaData/AddPhotoMetaData";
import ManageEnquiries from "./components/AdminPanel/ManageEnquiries/ManageEnquiries";

export const router = createBrowserRouter([
    {
        // path: '/', element: <App />
        path: '/', element: <>  <Navbar />
            <LandingPage /></>
    },
    {
        path: '/properties', element: <App />
    },
    {
        path: '/contact', element: <Contact />
    },
    {
        path: '/property/:slug', element: <>
            <Navbar />
            <PropertyPage />
        </>
    },
    {
        path: '/trip-checkout/:slug', element: <>
            <TripCheckoutPage />
        </>
    },
    {
        path: '/property-sign-up',
        element: <>
            <Navbar />
            <PropertySignUp />
        </>
    },
    {
        path: '/edit-property/:slug', element: <>
            <Navbar />

            <PropertySignUp />
        </>
    },
    {
        path: '/success', element: <SuccessPage />
    },
    {
        path: '/temp-landing-page', element: <LandingPage />
    },
    {
        path: '/property-selection', element: <>
            <Navbar />

            <PropertySelectPage />
        </>
    },
    {
        path: '/property-photos-upload/:slug', element: <>
            <Navbar />

            <PropertyPhotosUpload />
        </>
    },

    {
        path: '/property-photo-metadata/:slug', element: <>
            <Navbar />

            <AddPhotoMetaData />
        </>
    },
    {
        path: '/manage-enquiries', element: <>
            <Navbar />
            <ManageEnquiries />
        </>
    }
]);