import React, { useEffect, useState, useMemo } from 'react';
import { db } from '../../../../firebase'; // Ensure your Firebase configuration is correct
import { collection, query, where, getDocs } from 'firebase/firestore';

import useFiltersStore from '../../../../stores/useFiltersStore';

import PropertyCard from '../PropertyCard/PropertyCard'; // Ensure this path is correct
import Filters from '../Filters/Filters'; // Ensure this path is correct and import Filters component

import './PropertyGrid.css';

function PropertyGrid() {
    const { filter, searchTerm } = useFiltersStore(state => ({
        filter: state.filter,
        searchTerm: state.searchTerm
    }));

    const setFilter = useFiltersStore(state => state.setFilter);

    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [amenities, setAmenities] = useState([]);

    useEffect(() => {
        const fetchProperties = async () => {
            try {
                const propertiesCollection = collection(db, 'properties');
                const q = query(propertiesCollection, where('public', '==', true));
                const querySnapshot = await getDocs(q);
                const propertiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProperties(propertiesList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching properties: ", error);
                setLoading(false);
            }
        };

        const fetchAmenities = async () => {
            try {
                const amenitiesCollection = collection(db, 'amenities');
                const q = query(amenitiesCollection, where('category', '==', 'Location'));
                const querySnapshot = await getDocs(q);
                const amenitiesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAmenities(amenitiesList);
            } catch (error) {
                console.error("Error fetching amenities: ", error);
            }
        };

        fetchProperties();
        fetchAmenities();
    }, []);

    const filteredProperties = useMemo(() => {
        return properties.filter(property =>
            (filter === '' || (property.location && property.location.includes(filter))) &&
            (
                property.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (property.location && property.location.some(cat => cat.toLowerCase().includes(searchTerm.toLowerCase()))) ||
                property.address?.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                property.address?.suburb?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                property.address?.country?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [properties, filter, searchTerm]);

    return (
        <div className="grid-container">
            <Filters setFilter={setFilter} currentFilter={filter} amenities={amenities} />
            {
                loading ? (
                    <div className='loading-text'
                    >Loading...</div>
                ) : (
                    <div className='grid-property-section'>
                        {filteredProperties.length > 0 ? (
                            filteredProperties.map(property => (
                                <PropertyCard key={property.id} {...property} slug={property.slug} />
                            ))
                        ) : (
                            <div>No properties found</div>
                        )}
                    </div>
                )
            }
        </div>
    );
}

export default PropertyGrid;
