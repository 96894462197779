import React, { useEffect, useState } from 'react';
import BriefDescription from './PropertyInfo/BriefDescription/BriefDescription';
import ReviewsScroller from './PropertyInfo/ReviewsScroller/ReviewsScroller';
import PhotoGallery from './PropertyInfo/PhotoGallery/PhotoGallery';
import Amenities from './PropertyInfo/Amenities/Amenities';
import LocationInfo from './PropertyInfo/LocationInfo/LocationInfo';
import PropertyRulesScroller from './PropertyInfo/PropertyRulesScroller/PropertyRulesScroller';

import { propertyRules } from '../../../data/PropertyRules';
import './PropertyInfo.css';

const amenitiesIcons = {
    cleaning: "fa fa-broom",
    exterior: "fa fa-tree",
    interior: "fa fa-home",
    kitchen: "fa fa-utensils",
    backup_electricity: "fa fa-battery-full"
};

function PropertyInfo({ details }) {
    const [amenities, setAmenities] = useState([]);
    const [rules, setRules] = useState([]);

    useEffect(() => {
        const prepareAmenities = () => {
            const data = details;
            const fetchedAmenities = [
                ...((data.cleaning || []).map(item => ({ label: item, icon: amenitiesIcons.cleaning, category: 'Cleaning', description: data.cleaningComments }))),
                ...((data.exterior || []).map(item => ({ label: item, icon: amenitiesIcons.exterior, category: 'Exterior', description: data.exteriorComments }))),
                ...((data.interior || []).map(item => ({ label: item, icon: amenitiesIcons.interior, category: 'Interior', description: data.interiorComments }))),
                ...((data.kitchen || []).map(item => ({ label: item, icon: amenitiesIcons.kitchen, category: 'Kitchen', description: data.kitchenComments }))),
                ...((data.backup_electricity || []).map(item => ({ label: item, icon: amenitiesIcons.backup_electricity, category: 'Backup Electricity', description: data.backup_electricityComments })))
            ];
            setAmenities(fetchedAmenities);
        };

        const prepareRules = () => {
            const ruleData = details.rules;
            console.log('ruleData', ruleData);
            const fetchedRules = propertyRules.map(rule => ({
                header: rule.header,
                description: typeof rule.description === 'function' ? rule.description(ruleData) : rule.description
            }));
            console.log('fetchedRules', fetchedRules);
            setRules(fetchedRules);
        };

        prepareAmenities();
        prepareRules();
    }, [details]);

    const groupedAmenities = amenities.reduce((acc, curr) => {
        const category = curr.category;
        if (!acc[category]) {
            acc[category] = { name: category, description: curr.description, items: [] };
        }
        acc[category].items.push(curr);
        return acc;
    }, {});

    return (
        <div className="property-info">
            <div>
                <BriefDescription name={details.name} description={details.description} />
            </div>
            <div>
                <PhotoGallery photos={details.photos} />
            </div>
            {Object.keys(groupedAmenities).map((key, index) => (
                <Amenities key={index} name={groupedAmenities[key].name} items={groupedAmenities[key].items} />
            ))}
            <LocationInfo suburb={details.address?.suburb} city={details.address?.city} locationCoordinates={details.locationCoordinates} />
            <PropertyRulesScroller rules={rules} />
        </div>
    );
}

export default PropertyInfo;
