import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './TripCheckoutPage.css';

import ReviewsScroller from '../PropertyPage/PropertyInfo/ReviewsScroller/ReviewsScroller';
import TripHeader from './TripHeader/TripHeader';
import TripDetails from './TripDetails/TripDetails';
import PaymentForm from './PaymentForm/PaymentForm';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import { Button, TextField, Box, Typography } from '@mui/material';
import { db } from '../../../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import useDocumentTitleAndDescription from '../../../utils/PageTitles/useDocumentTitleAndDescription';
import ReactGA from 'react-ga4';

function TripCheckoutPage() {

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Extract slug from pathname
  const pathnameParts = location.pathname.split('/');
  const slug = pathnameParts[pathnameParts.length - 1];

  const [totalCost, setTotalCost] = useState(0);
  const [isButtonFixed, setIsButtonFixed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [guestCount, setGuestCount] = useState(2); // Default guest count
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState(null);


  const [error, setError] = useState(null);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false); // New state

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        setLoading(true);
        const propertiesCollection = collection(db, 'properties');
        const q = query(propertiesCollection, where('slug', '==', slug));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const propertyDoc = querySnapshot.docs[0];
          setProperty(propertyDoc.data());
          console.log('Property found:', propertyDoc.data());
        } else {
          setError('Property not found');
        }
      } catch (error) {
        console.error('Error fetching property:', error);
        setError('Error fetching property');
      } finally {

        setLoading(false);
      }
    };

    fetchProperty();
  }, [slug]);

  useEffect(() => {
    if (property) {
      const checkInDate = new Date(queryParams.checkIn);
      const checkOutDate = new Date(queryParams.checkOut);
      const nights = Math.floor((checkOutDate - checkInDate) / (1000 * 60 * 60 * 24));
      const cost = nights * property.priceFrom;
      setTotalCost(cost);
    }
  }, [queryParams, property]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const footer = document.querySelector('footer');
      const button = document.querySelector('.fixed-pay-button');
      if (!footer || !button) return;

      const footerRect = footer.getBoundingClientRect();
      const buttonRect = button.getBoundingClientRect();

      if (footerRect.top < window.innerHeight) {
        setIsButtonFixed(false);
      } else {
        setIsButtonFixed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleReserve = async () => {
    if (!email || !phoneNumber) {
      alert('Please enter your email and phone number.');
      return;
    }
    setLoading(true);
    const checkInDate = new Date(queryParams.checkIn);
    const checkOutDate = new Date(queryParams.checkOut);
    const nights = Math.floor((checkOutDate - checkInDate) / (1000 * 60 * 60 * 24));
    const formattedCheckInDate = checkInDate.toISOString().split('T')[0];
    const formattedCheckOutDate = checkOutDate.toISOString().split('T')[0];

    try {

      await addDoc(collection(db, 'enquiries'), {
        checkIn: formattedCheckInDate,
        checkOut: formattedCheckOutDate,
        email: email.trim(),
        phoneNumber: phoneNumber.trim(),
        totalCost,
        createdAt: new Date(),
        guestCount: parseInt(guestCount, 10),
        nights,
        propertyId: property.propertyId,
        responded: false,
        // Add doc ID
        id: '',
      });
      console.log('Enquiry added to Firestore');
      alert('Enquiry Received! We will contact you shortly.');
    } catch (error) {
      console.error('Error adding enquiry to Firestore:', error);
      alert('Error sending enquiry. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useDocumentTitleAndDescription(`Trip Checkout: ${property?.name ? property?.name : slug}`, 'Checkout page for your trip');
  // Send pageview event to Google Analytics

  const formattedTotalCost = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 0,
  }).format(totalCost);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'ZAR',
      minimumFractionDigits: 0,
    }).format(amount);
  };

  const handleImageLoad = () => {
    setBackgroundLoaded(true);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Typography variant="h6">Loading...</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <Typography variant="h6">{error}</Typography>
      </div>
    );
  }



  return (
    <>
      <Navbar />
      <div>
        {property && (
          <img
            src={property.photos.coverPhoto}
            alt="Background"
            className="trip-summary-background"
            onLoad={handleImageLoad}
            style={{ display: 'none' }}
          />
        )}
        <div
          className={`trip-summary-background ${backgroundLoaded ? 'loaded' : ''}`}
          style={{ backgroundImage: `url(${property ? property.photos?.coverPhoto?.url : ''})` }}
        />
        <div className={`trip-summary-container ${isMobile ? 'mobile' : ''}`}>
          <div className="trip-summary-left">
            {property && (
              <>
                <TripHeader propertyName={property.name} views="592" />
                <TripDetails checkInDate={queryParams.checkIn} checkOutDate={queryParams.checkOut} setGuestCount={setGuestCount} guestCount={guestCount} lodgeSlug={slug} />
                <div className="divider"></div>

                {isMobile ? (
                  <>
                    <div className="trip-summary-breakdown-box">
                      <div className="trip-summary-breakdown">
                        <h2>Cost breakdown</h2>
                        <p>{`${formatCurrency(property.priceFrom)} x ${Math.floor((new Date(queryParams.checkOut) - new Date(queryParams.checkIn)) / (1000 * 60 * 60 * 24))} nights`}</p>
                        <p>Total: {formattedTotalCost}</p>
                      </div>
                    </div>
                    <Box component="form" sx={{ mt: 3 }}>
                      <Typography variant="h6">Enter your details</Typography>
                      <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        margin="normal"
                        variant="outlined"
                      />
                    </Box>

                    {loading ? (
                      <div className={`fixed-pay-button`}>
                        <Button
                          variant="contained"
                          className="mobile-calendar-button"
                          color="inherit"
                          sx={{
                            padding: '20px 40px',
                            backgroundColor: '#fff',
                            '&:hover': { backgroundColor: '#e0e0e0' },
                          }}
                        >
                          Loading...
                        </Button>
                      </div>
                    ) : (
                      <div className={`fixed-pay-button`}>
                        <Button
                          variant="contained"
                          className="mobile-calendar-button"
                          color="inherit"
                          sx={{
                            backgroundColor: '#e8c456',
                            '&:hover': {
                              backgroundColor: '#dfc883',
                            },
                            color: 'white',
                          }}
                          onClick={handleReserve}
                        >
                          Enquire now!
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <PaymentForm setEmail={setEmail} setPhoneNumber={setPhoneNumber} totalCost={totalCost} property={property} />
                )}
              </>
            )}
          </div>
          {!isMobile && property && (
            <div className="trip-summary-right">
              <div className="trip-summary-breakdown-box">
                <div className="trip-summary-breakdown">
                  <h2>Cost breakdown</h2>
                  <p>{`${formatCurrency(property.priceFrom)} x ${Math.floor((new Date(queryParams.checkOut) - new Date(queryParams.checkIn)) / (1000 * 60 * 60 * 24))} nights`}</p>
                  <p>Total: {formattedTotalCost}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {!isMobile && property && (
          loading ? (
            <div className={`fixed-pay-button`}>
              <Button
                variant="contained"
                className="mobile-calendar-button"
                color="inherit"
                sx={{
                  padding: '20px 40px',
                  backgroundColor: '#fff',
                  '&:hover': { backgroundColor: '#e0e0e0' },
                }}
              >
                Loading...
              </Button>
            </div>
          ) : (
            <div className={`fixed-pay-button`}>
              <Button
                variant="contained"
                className="mobile-calendar-button"
                color="inherit"
                sx={{
                  backgroundColor: '#e8c456',
                  width: '100%',
                }}
                onClick={handleReserve}
              >
                Enquire now!
              </Button>
            </div>
          )
        )}
      </div>
      <Footer />
    </>
  );
}

export default TripCheckoutPage;
